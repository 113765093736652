import { getFragmentData } from "~/__generated__"
import { AudienceDemographics } from "./AudienceDemographics"
import { FullInfluencerFragment } from "~/__generated__/graphql"
import { TwitchIcon } from "~/common/SocialMediaIcons"
import { apiNumberDisplay, pricingDisplay } from "~/util/numbers"
import { IconHeading } from "../../components/IconHeading"
import { Label } from "./Label"
import { LabelHeader } from "./LabelHeader"
import { SOOCIAL_MEDIA_FEED_PRICING_FRAGMENT } from "../../api"

export const TwitchInfo = ({
  influencer,
}: {
  influencer: FullInfluencerFragment
}) => {
  if (!influencer.twitchProfile) {
    return null
  }

  return (
    <>
      <IconHeading
        icon={<TwitchIcon />}
        title="Twitch"
        rightSide={
          <a
            href={influencer.twitchProfile.url || ""}
            target="_blank"
            rel="noreferrer"
          >
            {influencer.twitchProfile.handle}
          </a>
        }
        className="mb-4"
      />
      <LabelHeader>Analytics</LabelHeader>
      <div className="grid grid-cols-4 gap-8 mb-6">
        <div>
          <Label>Subscribers</Label>
          <div>{apiNumberDisplay(influencer.twitchProfile.subscribers)}</div>
        </div>
        <div>
          <Label>AVG Stream Views</Label>
          <div>
            {apiNumberDisplay(influencer.twitchProfile.averageStreamViews)}
          </div>
        </div>
        <div>
          <Label>CCV</Label>
          <div>
            {apiNumberDisplay(influencer.twitchProfile.concurrentViewers)}
          </div>
        </div>
      </div>
      <LabelHeader>Pricing</LabelHeader>
      <div className="flex flex-row justify-stretch gap-8">
        <div className="flex-1">
          <Label>Stream</Label>
          <div>
            {pricingDisplay(
              getFragmentData(
                SOOCIAL_MEDIA_FEED_PRICING_FRAGMENT,
                influencer.twitchProfile.streamPricing
              )
            )}
          </div>
        </div>
        <div className="flex-1"></div>
        <div className="flex-1"></div>
        <div className="flex-1"></div>
      </div>
      <AudienceDemographics
        analytics={influencer.twitchProfile.profileAnalytics}
      />
      {influencer.twitchProfile.notes && (
        <div className="bg-gray10 p-4 mt-4">
          <Label>Notes</Label>
          {influencer.twitchProfile.notes}
        </div>
      )}
    </>
  )
}

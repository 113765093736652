import { gql } from "~/__generated__"

// This file is parsed also used by ruby, don't add anything to it aside from this query

export const CURRENT_USER_QUERY_DOCUMENT = gql(`
  query CurrentUserProvider {
    currentUser {
      ...CurrentUser
    }
  }
`)

export const CURRENT_USER_FRAGMENT = gql(`
  fragment CurrentUser on User {
    id
    email
    firstName
    lastName
    jobTitle
    isAdmin
    isOnboarded
    agencyBusinessType
    discarded
    createdAt
    notifyOfNewAgencies
    agencyRole
    agency {
      id
      name
      contactEmail
      agentName
      website
      phone
      logoThumbnailUrl
      businessType
    }
    canIndexRosterDatabase {
      value
    }
    canIndexMyRoster {
      value
    }
    canEditBusinessType {
      value
    }
  }
`)

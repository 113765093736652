import { zodResolver } from "@hookform/resolvers/zod"
import { useForm } from "react-hook-form"
import { useNavigate } from "react-router-dom"
import { z } from "zod"
import { BusinessTypeEnum } from "~/__generated__/graphql"
import { useCurrentUser } from "~/auth/CurrentUserContext"
import { businessTypeOptions } from "~/common/enums"
import { useSafeMutation } from "~/common/useSafeMutation"
import { displayErrors } from "~/common/validations"
import { Button } from "~/shadcn/ui/button"
import { Form, FormField, FormItem, FormMessage } from "~/shadcn/ui/form"
import { FloatingLabelInput } from "~/shadcn/ui/floating-label-input"
import { PhotoFormField } from "~/ui/PhotoFormField"
import { Select } from "~/ui/Select"
import { H1 } from "~/ui/typography"
import { UPDATE_USER_MUTATION } from "../api"
import { useOnCompleteOnboarding } from "../useOnCompleteOnboarding"

const formSchema = z.object({
  agency: z.object({
    logo: z.union([z.literal(undefined), z.literal(null), z.string()]),
    name: z.string().trim().min(1, "Agency name is required"),
    agentName: z.string().trim().min(1, "Contact name is required"),
    contactEmail: z
      .string()
      .trim()
      .min(1, "Email is required")
      .email({ message: "Please enter a valid email" }),
    website: z.union([
      z.literal(""),
      z.string().trim().url({ message: "Please enter a valid URL" }),
    ]),
    phone: z.string().trim(),
    businessType: z
      .string()
      .optional()
      .refine(
        (value) =>
          !value || Object.values(BusinessTypeEnum as any).includes(value),
        "Please select a valid business type"
      ),
  }),
})

type FormValues = z.infer<typeof formSchema>

export const OnboardingAgencyDetailsScreen = () => {
  const currentUser = useCurrentUser()
  const [runUpdateUser] = useSafeMutation(UPDATE_USER_MUTATION)
  const navigate = useNavigate()
  const { onCompleteOnboarding } = useOnCompleteOnboarding()

  const form = useForm<FormValues>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      agency: {
        logo: undefined, // undefined leaves logo alone unless explicitly changed
        name: currentUser.agency?.name || "",
        agentName: currentUser.agency?.agentName || "",
        contactEmail: currentUser.agency?.contactEmail || "",
        website: currentUser.agency?.website || "",
        phone: currentUser.agency?.phone || "",
        businessType:
          currentUser.agency?.businessType ||
          currentUser.agencyBusinessType ||
          "",
      },
    },
  })

  const onSubmit = async (values: FormValues) => {
    const { data, errors } = await runUpdateUser({
      variables: {
        input: {
          agency: {
            ...values.agency,
            businessType: undefined,
          },
        },
      },
    })

    if (errors) {
      displayErrors(errors, form.setError)
      console.error(errors)
    } else if (data) {
      onCompleteOnboarding()
    } else {
      console.error("unexpected response", { data, errors })
    }
  }

  return (
    <div className="flex-1 flex flex-col self-stretch">
      <div className="text-defaultSecondary mb-8">Step 2/2</div>
      <H1 className="mb-2">Company Details</H1>
      <p className="text-hoveredSecondary mb-8">
        This is the contact information that will be visible to others on the
        platform. For <b>talent agencies</b>, this is the contact information
        that will show up on your influencers profiles. If you would like to
        have different contact information for each influencer, you can override
        the contact email and name in every profile. For{" "}
        <b>marketing agencies</b>, this is the contact information that will
        show up to others on the platform. For <b>brands</b>, this information
        will remain hidden to other users.
      </p>
      <hr className="mb-8 border-0 border-b border-gray30" />
      <Form {...form}>
        <form
          onSubmit={form.handleSubmit(onSubmit)}
          className="flex-1 flex-grow flex flex-col gap-4"
        >
          <FormField
            control={form.control}
            name="agency.logo"
            render={({ field }) => (
              <FormItem>
                <PhotoFormField
                  name={field.name}
                  label="Logo (optional)"
                  initialImageUrl={currentUser.agency?.logoThumbnailUrl}
                />
              </FormItem>
            )}
          />
          <div className="flex flex-row gap-8 mt-4">
            <div className="flex-1">
              <FormField
                control={form.control}
                name="agency.name"
                render={({ field }) => (
                  <FormItem>
                    <FloatingLabelInput
                      {...field}
                      id="agency.name"
                      label="Company Name*"
                    />
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>
            <div className="flex-1">
              <FormField
                control={form.control}
                name="agency.agentName"
                render={({ field }) => (
                  <FormItem>
                    <FloatingLabelInput
                      {...field}
                      id="agency.agentName"
                      label="Contact Full Name*"
                    />
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>
          </div>
          <div className="flex flex-row gap-8">
            <div className="flex-1">
              <FormField
                control={form.control}
                name="agency.contactEmail"
                render={({ field }) => (
                  <FormItem>
                    <FloatingLabelInput
                      {...field}
                      id="agency.contactEmail"
                      label="Contact Email*"
                    />
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>
            <div className="flex-1">
              <FormField
                control={form.control}
                name="agency.phone"
                render={({ field }) => (
                  <FormItem>
                    <FloatingLabelInput
                      {...field}
                      id="agency.phone"
                      label="Contact Phone Number"
                    />
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>
          </div>
          <div className="flex flex-row gap-8">
            <div className="flex-1">
              <FormField
                control={form.control}
                name="agency.website"
                render={({ field }) => (
                  <FormItem>
                    <FloatingLabelInput
                      {...field}
                      id="agency.website"
                      label="Website"
                    />
                    <FormMessage />
                    <p className="text-xs text-gray50">
                      Example: https://www.clickstalent.com
                    </p>
                  </FormItem>
                )}
              />
            </div>
            <div className="flex-1">
              <FormField
                control={form.control}
                name="agency.businessType"
                render={({ field }) => (
                  <FormItem>
                    <Select
                      {...field}
                      value={businessTypeOptions.find(
                        (option) => option.value === field.value
                      )}
                      onChange={(option) => {
                        field.onChange(option?.value || "")
                      }}
                      options={businessTypeOptions}
                      isSearchable={false}
                      isClearable
                      placeholder="Business Type"
                      isDisabled={true}
                    />
                    <FormMessage />
                    <p className="text-xs text-gray50">
                      {field.value === BusinessTypeEnum.Talent
                        ? "A 'Talent Agency' represents influencers."
                        : field.value === BusinessTypeEnum.Marketing
                        ? "A 'Marketing Agency' sources influencers to run campaigns for brands."
                        : field.value === BusinessTypeEnum.Brand
                        ? "A 'Brand' sources influencers for their own campaigns."
                        : ""}
                    </p>
                  </FormItem>
                )}
              />
            </div>
          </div>
          <div className="self-stretch flex-1 flex-grow flex justify-between items-end">
            <Button type="button" variant="ghost" onClick={() => navigate(-1)}>
              Back
            </Button>
            <Button type="submit">Next</Button>
          </div>
        </form>
      </Form>
    </div>
  )
}

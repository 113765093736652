import { useFormContext, useWatch } from "react-hook-form"
import { useCurrentUser } from "~/auth/CurrentUserContext"
import {
  exclusivityOptions,
  genderOptions,
  languageOptions,
} from "~/common/enums"
import { FormField, FormItem, FormMessage } from "~/shadcn/ui/form"
import { FloatingLabelInput } from "~/shadcn/ui/floating-label-input"
import { CountrySelect } from "~/ui/CountrySelect"
import { Select } from "~/ui/Select"
import { StateSelect } from "~/ui/StateSelect"
import { InfluencerFormValues } from "../schema"
import { IconHeading } from "./IconHeading"

import IdCardIcon from "~/images/icons/id-card.svg?react"
import MapMarkerIcon from "~/images/icons/map-marker.svg?react"
import BriefcaseIcon from "~/images/icons/briefcase.svg?react"

export const PersonalInformationFields = () => {
  const currentUser = useCurrentUser()
  const form = useFormContext<InfluencerFormValues>()

  const countryCode = useWatch({ control: form.control, name: "countryCode" })

  return (
    <div>
      <IconHeading icon={<IdCardIcon />} title="Basic Info" />
      <div className="flex flex-row gap-8">
        <div className="flex-1 flex flex-col gap-4">
          <FormField
            control={form.control}
            name="name"
            render={({ field }) => (
              <FormItem>
                <FloatingLabelInput {...field} id="name" label="Full Name*" />
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="gender"
            render={({ field }) => (
              <FormItem>
                <Select
                  {...field}
                  value={genderOptions.find(
                    (option) => option.value === field.value
                  )}
                  onChange={(option) => {
                    field.onChange(option?.value || "")
                  }}
                  options={genderOptions}
                  isSearchable={false}
                  isClearable
                  placeholder="Gender"
                />
                <FormMessage />
              </FormItem>
            )}
          />
        </div>
        <div className="flex-1 flex flex-col gap-4">
          <FormField
            control={form.control}
            name="age"
            render={({ field }) => (
              <FormItem>
                <FloatingLabelInput
                  {...field}
                  id="age"
                  label="Age"
                  type="number"
                  min={1}
                  step={1}
                  onChange={(e) => {
                    const parsed = parseInt(e.target.value, 10)
                    if (isNaN(parsed)) {
                      field.onChange("")
                    } else {
                      field.onChange(parsed)
                    }
                  }}
                />
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name="languages"
            render={({ field }) => (
              <FormItem>
                <Select
                  {...field}
                  value={languageOptions.filter((option) =>
                    field.value.includes(option.value)
                  )}
                  onChange={(options) => {
                    field.onChange(options.map((option) => option.value))
                  }}
                  options={languageOptions}
                  isClearable
                  placeholder="Language(s)"
                  isMulti
                />
                <FormMessage />
              </FormItem>
            )}
          />
        </div>
      </div>
      <IconHeading icon={<MapMarkerIcon />} title="Location" />
      <div className="flex flex-col gap-4">
        <div className="flex flex-row gap-8">
          <div className="flex-1">
            <FormField
              control={form.control}
              name="countryCode"
              render={({ field }) => (
                <FormItem>
                  <CountrySelect
                    {...field}
                    onChange={(countryCode) => {
                      field.onChange(countryCode)
                    }}
                    isSearchable
                    isClearable
                    placeholder="Country"
                  />
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>
          <div className="flex-1">
            <FormField
              control={form.control}
              name="state"
              render={({ field }) => (
                <FormItem>
                  <StateSelect
                    {...field}
                    onChange={(state) => {
                      field.onChange(state)
                    }}
                    countryCode={countryCode}
                    key={`state-${countryCode}`}
                  />
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>
        </div>
        <div className="flex flex-row gap-8">
          <div className="flex-1">
            <FormField
              control={form.control}
              name="city"
              render={({ field }) => (
                <FormItem>
                  <FloatingLabelInput {...field} id="city" label="City" />
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>
          <div className="flex-1"></div>
        </div>
      </div>
      <IconHeading icon={<BriefcaseIcon />} title="Agency" />
      <div className="flex flex-col gap-4">
        <div className="flex flex-row gap-8">
          <div className="flex-1">
            <FormField
              control={form.control}
              name="exclusivity"
              render={({ field }) => (
                <FormItem>
                  <Select
                    {...field}
                    value={exclusivityOptions.find(
                      (option) => option.value === field.value
                    )}
                    onChange={(option) => {
                      field.onChange(option?.value || "")
                    }}
                    options={exclusivityOptions}
                    isSearchable={false}
                    isClearable
                    placeholder="Exclusivity*"
                  />
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>
          <div className="flex-1">
            <FormField
              control={form.control}
              name="agentName"
              render={({ field }) => (
                <FormItem>
                  <FloatingLabelInput
                    {...field}
                    id="agentName"
                    label="Agent Full Name"
                  />
                  <FormMessage />
                </FormItem>
              )}
            />
            {currentUser.agency?.name && (
              <p className="text-gray50 text-sm mt-2">
                Defaults to "{currentUser.agency.agentName}" if left blank
              </p>
            )}
          </div>
        </div>
        <div className="flex flex-row gap-8">
          <div className="flex-1">
            <FormField
              control={form.control}
              name="agentEmail"
              render={({ field }) => (
                <FormItem>
                  <FloatingLabelInput
                    {...field}
                    id="agentEmail"
                    label="Agent Email"
                  />
                  <FormMessage />
                </FormItem>
              )}
            />
            {currentUser.agency?.contactEmail && (
              <p className="text-gray50 text-sm mt-2">
                Defaults to "{currentUser.agency.contactEmail}" if left blank
              </p>
            )}
          </div>
          <div className="flex-1">
            <FormField
              control={form.control}
              name="agentPhone"
              render={({ field }) => (
                <FormItem>
                  <FloatingLabelInput
                    {...field}
                    id="agentPhone"
                    label="Agent Phone"
                  />
                  <FormMessage />
                </FormItem>
              )}
            />
            {currentUser.agency?.phone && (
              <p className="text-gray50 text-sm mt-2">
                Defaults to "{currentUser.agency.phone}" if left blank
              </p>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

import {
  SocialMediaFeedPricingFragment,
  SocialMediaRateTypeEnum,
} from "~/__generated__/graphql"

export const isNumber = (value?: unknown): value is number => {
  return typeof value === "number" && !isNaN(value)
}

export const friendlyNumber = (num?: number | null, decimals: number = 1) => {
  if (num === null || num === undefined) return "-"
  if (num < 1000) return num.toString()
  if (num < 1000000)
    return `${(num / 1000).toFixed(decimals).replace(/\.0+$/, "")}K`
  return `${(num / 1000000).toFixed(decimals).replace(/\.0+$/, "")}M`
}

export const friendlyNumberAndBeyond = (
  num: number | null | undefined,
  max: number,
  decimals: number = 1
) => {
  if (num === max) return `${friendlyNumber(num)}+`
  return friendlyNumber(num, decimals)
}

export const moneyAndBeyond = (num: number | null | undefined, max: number) => {
  if (num === max) return `${moneyFormatter.format(num)}+`
  return moneyFormatter.format(num || 0)
}

export const moneyToCents = (money: string | number) => {
  return parseInt((parseFloat(money.toString()) * 100).toString(), 10)
}

export const inputToInt = (input?: string | number) => {
  return parseInt(input?.toString() || "") || null
}

export const inputToFloat = (input?: string | number) => {
  return parseFloat(input?.toString() || "") || null
}

export const rateToPercent = (rate?: string | number) => {
  return rate ? parseFloat(rate.toString()) * 100 : null
}

export const percentToRate = (percent?: string | number) => {
  if (!percent) return null
  let value = parseFloat(percent.toString())
  if (Number.isNaN(value)) return null
  return value / 100
}

export const moneyFormatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
  maximumFractionDigits: 0,
})

export const numberFormatter = new Intl.NumberFormat("en-US")

export const percentFormatter = new Intl.NumberFormat("en-US", {
  style: "percent",
  minimumFractionDigits: 0,
  maximumFractionDigits: 2,
})

export const pricingDisplay = (
  pricing?: SocialMediaFeedPricingFragment | null
) => {
  if (pricing) {
    if (
      pricing.rateType === SocialMediaRateTypeEnum.Flat &&
      pricing.rateFlatCents
    ) {
      return moneyFormatter.format(pricing.rateFlatCents / 100)
    } else if (
      pricing.rateType === SocialMediaRateTypeEnum.Range &&
      pricing.rateMaxCents &&
      pricing.rateMinCents
    ) {
      return `${moneyFormatter.format(
        pricing.rateMinCents / 100
      )} - ${moneyFormatter.format(pricing.rateMaxCents / 100)}`
    }
  }
  return null
}

export const numberDisplay = (number?: number | null) => {
  if (number || number === 0) {
    return numberFormatter.format(number)
  }
  return null
}

export const apiNumberDisplay = (number?: number | null) => {
  if (number === 0) {
    return "N/A" // api sends 0s when it's not working correctly'
  } else if (number) {
    return numberFormatter.format(number)
  }
  return null
}

export const percentDisplay = (number?: number | null) => {
  if (number || number === 0) {
    return percentFormatter.format(number)
  }
  return null
}

export const apiPercentDisplay = (number?: number | null) => {
  if (number === 0) {
    return "N/A" // api sends 0s when it's not working correctly'
  } else if (number) {
    return percentFormatter.format(number)
  }
  return null
}

import { useState } from "react"
import toast from "react-hot-toast"
import { BusinessTypeEnum, JobTitleEnum } from "~/__generated__/graphql"
import { useCurrentUser } from "~/auth/CurrentUserContext"
import { useHeaderText } from "~/common/HeaderContext"
import { useSafeMutation } from "~/common/useSafeMutation"
import { displayErrors } from "~/common/validations"
import {
  UserAccountForm,
  UserAccountFormValues,
} from "~/screens/users/components/UserAccountForm"
import { Card, CardContent } from "~/shadcn/ui/card"
import { ModalDialog } from "~/ui/ModalDialog"
import { ChangePasswordModal } from "../modals/ChangePasswordModal"
import { UPDATE_USER_MUTATION } from "../onboarding/api"
import { UserDeleteButton } from "../users/components/UserDeleteButton"

export const SettingsAccountScreen = () => {
  const currentUser = useCurrentUser()
  const [runUpdateUser, updateUserResult] =
    useSafeMutation(UPDATE_USER_MUTATION)

  useHeaderText({ header: "Account", subheader: null })

  const [isChangePasswordModalOpen, setIsChangePasswordModalOpen] =
    useState(false)

  const onSubmit = async (values: UserAccountFormValues) => {
    const { data, errors } = await runUpdateUser({
      variables: {
        input: {
          firstName: values.firstName,
          lastName: values.lastName,
          jobTitle: (values.jobTitle || null) as JobTitleEnum | null,
          notifyOfNewAgencies: values.notifyOfNewAgencies,
          agency: {
            ...values.agency,
            businessType: currentUser.canEditBusinessType.value
              ? (values.agency.businessType as BusinessTypeEnum)
              : undefined,
          },
        },
      },
    })

    if (errors) {
      displayErrors(errors)
      console.error(errors)
    } else if (data) {
      toast.success("Account updated")
      setKey((key) => key + 1)
    } else {
      console.error("unexpected response", { data, errors })
    }
  }

  const [key, setKey] = useState(0)

  return (
    <div className="h-full pb-4 relative">
      <Card className="absolute left-0 right-0 top-0 bottom-4 overflow-auto">
        <CardContent className="flex-1 pt-4 flex flex-col">
          <UserAccountForm
            key={key}
            initialValues={{
              firstName: currentUser.firstName || "",
              lastName: currentUser.lastName || "",
              jobTitle: currentUser.jobTitle || "",
              notifyOfNewAgencies: currentUser.notifyOfNewAgencies,
              agency: {
                agentName: currentUser.agency?.agentName ?? "",
                contactEmail: currentUser.agency?.contactEmail ?? "",
                logo: undefined,
                name: currentUser.agency?.name ?? "",
                phone: currentUser.agency?.phone ?? "",
                website: currentUser.agency?.website ?? "",
                businessType: currentUser.agency?.businessType ?? "",
              },
            }}
            onSubmit={onSubmit}
            isLoading={updateUserResult.loading}
            error={updateUserResult.error}
            onChangePassword={() => setIsChangePasswordModalOpen(true)}
            email={currentUser.email ?? ""}
            logoThumbnailUrl={currentUser.agency?.logoThumbnailUrl ?? null}
            deleteButton={<UserDeleteButton userId={currentUser.id} />}
          />
          <ModalDialog
            open={isChangePasswordModalOpen}
            onClose={() => setIsChangePasswordModalOpen(false)}
          >
            <ChangePasswordModal
              onCloseModal={() => setIsChangePasswordModalOpen(false)}
            />
          </ModalDialog>
        </CardContent>
      </Card>
    </div>
  )
}

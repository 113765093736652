import { useFormContext, useWatch } from "react-hook-form"
import { PlatformEnum } from "~/__generated__/graphql"
import { friendlyNumberAndBeyond, moneyAndBeyond } from "~/util/numbers"
import { SocialMediaFieldLabel } from "./SocialMediaFieldLabel"
import {
  ENG_RATE_MAX,
  ENG_RATE_MIN,
  FOLLOWERS_MAX,
  FOLLOWERS_MIN,
  FOLLOWERS_STEP,
  PRICING_CENTS_MIN,
  PRICING_CENTS_MAX,
  PRICING_CENTS_STEP,
  SearchValues,
  VIEWS_MAX,
  VIEWS_MIN,
  VIEWS_STEP,
} from "../../schema"

import { RangeSlider } from "~/ui/RangeSlider"
import { SocialMediaFilter } from "./SocialMediaFilter"

export const TikTokFilter = () => {
  const form = useFormContext<SearchValues>()

  const enabled = useWatch({
    control: form.control,
    name: "tikTokEnabled",
  })

  const followersMin =
    useWatch({
      control: form.control,
      name: "tikTokFollowersMin",
    }) || FOLLOWERS_MIN

  const followersMax =
    useWatch({
      control: form.control,
      name: "tikTokFollowersMax",
    }) || FOLLOWERS_MAX

  const avgViewsMin =
    useWatch({
      control: form.control,
      name: "tikTokAvgViewsMin",
    }) || VIEWS_MIN

  const avgViewsMax =
    useWatch({
      control: form.control,
      name: "tikTokAvgViewsMax",
    }) || VIEWS_MAX

  const engagementRateMin =
    useWatch({
      control: form.control,
      name: "tikTokEngagementRateMin",
    }) || ENG_RATE_MIN

  const engagementRateMax =
    useWatch({
      control: form.control,
      name: "tikTokEngagementRateMax",
    }) || ENG_RATE_MAX

  const videoRateMin =
    useWatch({
      control: form.control,
      name: "tikTokVideoRateCentsMin",
    }) || PRICING_CENTS_MIN

  const videoRateMax =
    useWatch({
      control: form.control,
      name: "tikTokVideoRateCentsMax",
    }) || PRICING_CENTS_MAX

  return (
    <SocialMediaFilter
      enabledFieldName="tikTokEnabled"
      hasRatesFieldName="tikTokHasRates"
      platform={PlatformEnum.Tiktok}
      title="TikTok"
    >
      <div>
        <SocialMediaFieldLabel text="Followers" />
        <RangeSlider
          min={FOLLOWERS_MIN}
          max={FOLLOWERS_MAX}
          step={FOLLOWERS_STEP}
          value={[followersMin, followersMax]}
          onAfterChange={(values) => {
            const [min, max] = values
            form.setValue("tikTokFollowersMin", min)
            form.setValue("tikTokFollowersMax", max)
          }}
          disabled={!enabled}
          format={(n) => friendlyNumberAndBeyond(n, FOLLOWERS_MAX, 2)}
        />
      </div>
      <div>
        <SocialMediaFieldLabel text="Average Views" />
        <RangeSlider
          min={VIEWS_MIN}
          max={VIEWS_MAX}
          step={VIEWS_STEP}
          value={[avgViewsMin, avgViewsMax]}
          onAfterChange={(values) => {
            const [min, max] = values
            form.setValue("tikTokAvgViewsMin", min)
            form.setValue("tikTokAvgViewsMax", max)
          }}
          disabled={!enabled}
          format={(n) => friendlyNumberAndBeyond(n, VIEWS_MAX)}
        />
      </div>
      <div>
        <SocialMediaFieldLabel text="Engagement Rate" />
        <RangeSlider
          min={ENG_RATE_MIN * 100}
          max={ENG_RATE_MAX * 100}
          step={1}
          value={[engagementRateMin * 100, engagementRateMax * 100]}
          onAfterChange={(values) => {
            const [min, max] = values
            form.setValue("tikTokEngagementRateMin", min / 100)
            form.setValue("tikTokEngagementRateMax", max / 100)
          }}
          disabled={!enabled}
          format={(value) => `${value}%`}
        />
      </div>
      <div>
        <SocialMediaFieldLabel text="Video Rate" />
        <RangeSlider
          min={PRICING_CENTS_MIN / 100}
          max={PRICING_CENTS_MAX / 100}
          step={PRICING_CENTS_STEP / 100}
          value={[videoRateMin / 100, videoRateMax / 100]}
          onAfterChange={(values) => {
            const [min, max] = values
            form.setValue("tikTokVideoRateCentsMin", min * 100)
            form.setValue("tikTokVideoRateCentsMax", max * 100)
          }}
          disabled={!enabled}
          format={(n) => moneyAndBeyond(n, PRICING_CENTS_MAX / 100)}
        />
      </div>
    </SocialMediaFilter>
  )
}

import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react"
import invariant from "tiny-invariant"
import { useDocumentTitle } from "./useDocumentTitle"

interface HeaderContextType {
  headerText: string
  subheaderText?: string
  setHeaderText: (text: string) => void
  setSubheaderText: (text?: string) => void
  headerBar: React.ReactNode | null
  setHeaderBar: (bar: React.ReactNode | null) => void
}

export const HeaderContext = createContext<HeaderContextType | null>(null)

export const HeaderProvider = ({ children }: { children: ReactNode }) => {
  const [headerText, setHeaderText] = useState<string>("")
  const [subheaderText, setSubheaderText] = useState<string | undefined>("")
  const [headerBar, setHeaderBar] = useState<React.ReactNode | null>(null)

  const value = useMemo(
    () => ({
      headerText,
      setHeaderText,
      subheaderText,
      setSubheaderText,
      headerBar,
      setHeaderBar,
    }),
    [
      headerText,
      setHeaderText,
      subheaderText,
      setSubheaderText,
      headerBar,
      setHeaderBar,
    ]
  )

  return (
    <HeaderContext.Provider value={value}>{children}</HeaderContext.Provider>
  )
}

export const useHeaderContext = () => {
  const contextValue = useContext(HeaderContext)
  invariant(contextValue, "Context has not been Provided!")
  return contextValue
}

export const useHeaderText = ({
  header,
  subheader,
}: {
  header: string
  subheader: string | null
}) => {
  useDocumentTitle(header)
  const ctx = useHeaderContext()
  useEffect(() => {
    ctx.setHeaderText(header)
    ctx.setSubheaderText(subheader ?? undefined)
  }, [ctx, header, subheader])
}

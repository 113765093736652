import { getFragmentData } from "~/__generated__"
import { FullInfluencerFragment } from "~/__generated__/graphql"
import { AudienceDemographics } from "./AudienceDemographics"
import { TikTokIcon } from "~/common/SocialMediaIcons"
import { HelpPopover } from "~/ui/HelpPopover"
import {
  apiNumberDisplay,
  apiPercentDisplay,
  pricingDisplay,
} from "~/util/numbers"
import { IconHeading } from "../../components/IconHeading"
import { Label } from "./Label"
import { LabelHeader } from "./LabelHeader"
import { SOOCIAL_MEDIA_FEED_PRICING_FRAGMENT } from "../../api"

export const TikTokInfo = ({
  influencer,
}: {
  influencer: FullInfluencerFragment
}) => {
  if (!influencer.tikTokProfile) {
    return null
  }

  return (
    <>
      <IconHeading
        title="TikTok"
        rightSide={
          <a
            href={influencer.tikTokProfile.url || ""}
            target="_blank"
            rel="noreferrer"
          >
            {influencer.tikTokProfile.handle}
          </a>
        }
        icon={<TikTokIcon />}
        className="mb-4"
      />
      <LabelHeader>Analytics</LabelHeader>
      <div className="grid grid-cols-4 gap-8 mb-6">
        <div>
          <Label>Followers</Label>
          <div>{apiNumberDisplay(influencer.tikTokProfile.followers)}</div>
        </div>
        <div>
          <Label>AVG Likes</Label>
          <div>{apiNumberDisplay(influencer.tikTokProfile.averageLikes)}</div>
        </div>
        <div>
          <Label>AVG Views</Label>
          <div>{apiNumberDisplay(influencer.tikTokProfile.averageViews)}</div>
        </div>
        <div>
          <div className="flex flex-row gap-2">
            <Label>ENG Rate</Label>
            <HelpPopover helpText="AVG Likes / AVG Views" />
          </div>
          <div>
            {apiPercentDisplay(influencer.tikTokProfile.engagementRate)}
          </div>
        </div>
      </div>
      <LabelHeader>Pricing</LabelHeader>
      <div className="flex flex-row justify-stretch gap-8">
        <div className="flex-1">
          <Label>Video post</Label>
          <div>
            {pricingDisplay(
              getFragmentData(
                SOOCIAL_MEDIA_FEED_PRICING_FRAGMENT,
                influencer.tikTokProfile.videoPricing
              )
            )}
          </div>
        </div>
        <div className="flex-1">
          <Label>Link in Bio</Label>
          <div>
            {pricingDisplay(
              getFragmentData(
                SOOCIAL_MEDIA_FEED_PRICING_FRAGMENT,
                influencer.tikTokProfile.linkInBioPricing
              )
            )}
          </div>
        </div>
        <div className="flex-1"></div>
        <div className="flex-1"></div>
      </div>
      <AudienceDemographics
        analytics={influencer.tikTokProfile.profileAnalytics}
      />
      {influencer.tikTokProfile.notes && (
        <div className="bg-gray10 p-4 mt-4">
          <Label>Notes</Label>
          {influencer.tikTokProfile.notes}
        </div>
      )}
    </>
  )
}

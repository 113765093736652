import { useQuery } from "@apollo/client"
import React from "react"
import invariant from "tiny-invariant"
import { getFragmentData } from "~/__generated__"
import { PlatformEnum } from "~/__generated__/graphql"
import { useHeaderText } from "~/common/HeaderContext"
import { GraphqlError } from "~/components/GraphqlError"
import { SectionHeader } from "~/components/SectionHeader"
import { LoadingIndicatorCentered } from "~/ui/LoadingIndicator"
import { query, agencyFragment, pricingFragment } from "./api"
import { AgencyCards } from "./components/AgencyCards"
import { StatCard } from "./components/StatCard"
import { PlatformPricing } from "./components/PlatformPricing"
import { categoryName, creatorTypeName, platformName } from "~/common/enums"
import { platformLogoMap } from "~/common/SocialMediaIcons"

export const DashboardScreen: React.FC = () => {
  const result = useQuery(query, {})
  useHeaderText({ header: "Dashboard", subheader: null })

  if (result.loading) {
    return <LoadingIndicatorCentered />
  }

  if (result.error) {
    return <GraphqlError error={result.error} />
  }

  invariant(result.data)

  return (
    <div className="overflow-auto">
      <SectionHeader
        title="TALENT AGENCIES"
        count={result.data.totals.talentAgencies}
        helpText="Each agency's total followings per platform"
      />
      <div className="grid grid-cols-[repeat(4,minmax(330px,1fr))] gap-2">
        <AgencyCards
          platform={PlatformEnum.Tiktok}
          agencies={getFragmentData(
            agencyFragment,
            result.data.topAgencies.tiktok
          )}
        />
        <AgencyCards
          platform={PlatformEnum.Instagram}
          agencies={getFragmentData(
            agencyFragment,
            result.data.topAgencies.instagram
          )}
        />
        <AgencyCards
          platform={PlatformEnum.Youtube}
          agencies={getFragmentData(
            agencyFragment,
            result.data.topAgencies.youtube
          )}
        />
        <AgencyCards
          platform={PlatformEnum.Twitch}
          agencies={getFragmentData(
            agencyFragment,
            result.data.topAgencies.twitch
          )}
        />
      </div>
      <SectionHeader
        title="INFLUENCERS"
        count={result.data.totals.influencers}
      />
      <div className="mt-4 grid grid-cols-[repeat(4,minmax(330px,1fr))] gap-2">
        <StatCard
          title="Types of Influencers"
          stats={result.data.influencerStats.topCreatorTypes.map((stat) => ({
            label: creatorTypeName(stat.creatorType),
            count: stat.count,
            creatorType: stat.creatorType,
          }))}
        />
        <StatCard
          title="Top Categories"
          stats={result.data.influencerStats.topCategories.map((stat) => ({
            label: categoryName(stat.category),
            count: stat.count,
          }))}
          showColorCircle={true}
        />
        <StatCard
          title="Top Countries"
          stats={result.data.influencerStats.topCountries.map((stat) => ({
            label: stat.country,
            count: stat.count,
            emoji: stat.countryFlagEmoji,
          }))}
        />
        <StatCard
          title="Platforms"
          stats={result.data.influencerStats.topPlatforms.map((stat) => ({
            label: platformName(stat.platform),
            count: stat.count,
            icon: platformLogoMap[stat.platform],
          }))}
        />
      </div>
      <SectionHeader
        title="PRICING"
        helpText="The average pricing per deliverable for influencers who have rates marked in their profile"
      />
      <div className="mt-4 grid grid-cols-[repeat(4,minmax(330px,1fr))] gap-2">
        <PlatformPricing
          platform={PlatformEnum.Tiktok}
          stats={[
            {
              label: "Video Post",
              stats: getFragmentData(
                pricingFragment,
                result.data.platformStats.tiktok.video
              ),
            },
            {
              label: "Link in Bio",
              stats: getFragmentData(
                pricingFragment,
                result.data.platformStats.tiktok.linkInBio
              ),
            },
          ]}
        />
        <PlatformPricing
          platform={PlatformEnum.Instagram}
          stats={[
            {
              label: "Photo",
              stats: getFragmentData(
                pricingFragment,
                result.data.platformStats.instagram.photo
              ),
            },
            {
              label: "Reels",
              stats: getFragmentData(
                pricingFragment,
                result.data.platformStats.instagram.reels
              ),
            },
            {
              label: "Story",
              stats: getFragmentData(
                pricingFragment,
                result.data.platformStats.instagram.story
              ),
            },
            {
              label: "Link in Bio",
              stats: getFragmentData(
                pricingFragment,
                result.data.platformStats.instagram.linkInBio
              ),
            },
          ]}
        />
        <PlatformPricing
          platform={PlatformEnum.Youtube}
          stats={[
            {
              label: "Integration",
              stats: getFragmentData(
                pricingFragment,
                result.data.platformStats.youtube.integration
              ),
            },
            {
              label: "Dedication",
              stats: getFragmentData(
                pricingFragment,
                result.data.platformStats.youtube.dedication
              ),
            },
            {
              label: "Shorts",
              stats: getFragmentData(
                pricingFragment,
                result.data.platformStats.youtube.shorts
              ),
            },
          ]}
        />
        <PlatformPricing
          platform={PlatformEnum.Twitch}
          stats={[
            {
              label: "Stream",
              stats: getFragmentData(
                pricingFragment,
                result.data.platformStats.twitch.stream
              ),
            },
          ]}
        />
      </div>
    </div>
  )
}

import { createContext, useContext, useState, useMemo } from "react"
import { useSearchParams } from "react-router-dom"
import { influencerSearchParamsSchema } from "./influencerSearchParamsSchema"
import { InfluencerDialogType } from "./InfluencerDialogType"
import { BulkUploadScreen } from "~/screens/influencers/bulk-upload/BulkUploadScreen"
import { NewInfluencerScreen } from "~/screens/influencers/NewInfluencerScreen"
import { EditInfluencerScreen } from "~/screens/influencers/EditInfluencerScreen"
import { ViewInfluencerScreen } from "~/screens/influencers/view-influencer/ViewInfluencerScreen"
import { Tab } from "~/screens/influencers/components/InfluencerTabBar"
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from "~/shadcn/ui/alert-dialog"
import { ModalDialog } from "~/ui/ModalDialog"

interface InfluencerActionsContextType {
  openDialogType: InfluencerDialogType | null
  setOpenDialogType: (dialogType: InfluencerDialogType | null) => void
  setActiveInfluencerId: (id: string) => void
}

const InfluencerActionsContext =
  createContext<InfluencerActionsContextType | null>(null)

export const InfluencerActionsProvider = ({
  children,
}: {
  children: React.ReactNode
}) => {
  const [searchParams, setSearchParams] = useSearchParams()
  const [isDirty, setIsDirty] = useState(false)
  const [showUnsavedChangesDialog, setShowUnsavedChangesDialog] =
    useState(false)

  const params = useMemo(() => {
    const result = influencerSearchParamsSchema.safeParse(
      Object.fromEntries(searchParams.entries())
    )
    return result.success ? result.data : {}
  }, [searchParams])

  const setOpenDialogType = (dialogType: InfluencerDialogType | null) => {
    setSearchParams(
      (params) => {
        if (dialogType === null) {
          params.delete("dialog")
        } else {
          params.set("dialog", dialogType)
        }
        return params
      },
      { replace: true }
    )
  }

  const setActiveInfluencerId = (id: string | null) => {
    setSearchParams(
      (params) => {
        if (id === null) {
          params.delete("influencerId")
        } else {
          params.set("influencerId", id)
        }
        return params
      },
      { replace: true }
    )
  }

  const value = {
    openDialogType: params.dialog ?? null,
    setOpenDialogType,
    setActiveInfluencerId,
  }

  return (
    <InfluencerActionsContext.Provider value={value}>
      {children}

      <ModalDialog
        open={params.dialog === InfluencerDialogType.NewInfluencerManual}
        onClose={() => {
          if (isDirty) {
            setShowUnsavedChangesDialog(true)
          } else {
            setOpenDialogType(null)
          }
        }}
        className="sm:p-0"
      >
        <NewInfluencerScreen
          onClose={(currentTab, reset) => {
            if (reset) {
              setIsDirty(false)
            }

            if (isDirty && !reset) {
              setShowUnsavedChangesDialog(true)
            } else {
              setOpenDialogType(null)
            }
          }}
          onChangeDirty={setIsDirty}
        />
      </ModalDialog>

      <ModalDialog
        open={params.dialog === InfluencerDialogType.BulkUpload}
        onClose={() => setOpenDialogType(null)}
        className="sm:p-0"
      >
        <BulkUploadScreen onClose={() => setOpenDialogType(null)} />
      </ModalDialog>

      <ModalDialog
        open={
          params.dialog === InfluencerDialogType.ViewInfluencer &&
          !!params.influencerId
        }
        onClose={() => {
          setSearchParams((params) => {
            params.delete("tab")
            return params
          })
          setOpenDialogType(null)
          setActiveInfluencerId(null)
        }}
      >
        {params.influencerId && (
          <ViewInfluencerScreen
            influencerId={params.influencerId}
            actionable
            onEditClick={(currentTab) => {
              setSearchParams((params) => {
                params.set("tab", currentTab)
                return params
              })
              setOpenDialogType(InfluencerDialogType.EditInfluencer)
            }}
            onDeleteClick={() => setOpenDialogType(null)}
            initialTab={(params.tab as Tab) || Tab.SocialMedia}
          />
        )}
      </ModalDialog>

      <ModalDialog
        open={
          params.dialog === InfluencerDialogType.EditInfluencer &&
          !!params.influencerId
        }
        onClose={() => {
          if (isDirty) {
            setShowUnsavedChangesDialog(true)
          } else {
            setOpenDialogType(null)
          }
        }}
      >
        {params.influencerId && (
          <EditInfluencerScreen
            influencerId={params.influencerId}
            onClose={(currentTab, reset) => {
              if (reset) {
                setIsDirty(false)
              }

              if (isDirty && !reset) {
                setShowUnsavedChangesDialog(true)
              } else {
                setSearchParams((params) => {
                  params.set("tab", currentTab)
                  return params
                })
                setOpenDialogType(InfluencerDialogType.ViewInfluencer)
              }
            }}
            initialTab={(params.tab as Tab) || Tab.PersonalInformation}
            onChangeDirty={setIsDirty}
          />
        )}
      </ModalDialog>

      <AlertDialog
        open={showUnsavedChangesDialog}
        onOpenChange={setShowUnsavedChangesDialog}
      >
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>Unsaved Changes</AlertDialogTitle>
            <AlertDialogDescription>
              You have unsaved changes. Are you sure you want to cancel?
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel>No, continue editing</AlertDialogCancel>
            <AlertDialogAction
              onClick={() => {
                setShowUnsavedChangesDialog(false)
                setOpenDialogType(null)
                setIsDirty(false)
              }}
            >
              Yes, discard changes
            </AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </InfluencerActionsContext.Provider>
  )
}

export const useInfluencerActions = () => {
  const context = useContext(InfluencerActionsContext)
  if (context === null) {
    throw new Error(
      "useInfluencerActions must be used within a InfluencerActionsProvider"
    )
  }
  return context
}

import { useQuery } from "@apollo/client"
import { useState } from "react"
import toast from "react-hot-toast"
import { getFragmentData, gql } from "~/__generated__"
import {
  AgencyRoleEnum,
  SortDirectionEnum,
  UserSortFieldEnum,
} from "~/__generated__/graphql"
import { useCurrentUser } from "~/auth/CurrentUserContext"
import { useHeaderText } from "~/common/HeaderContext"
import { useSafeMutation } from "~/common/useSafeMutation"
import { displayErrors } from "~/common/validations"
import { GraphqlError } from "~/components/GraphqlError"
import envelope from "~/images/icons/envelope"
import trash from "~/images/icons/trash"
import { ADMIN_RESEND_USER_INVITE_MUTATION } from "~/screens/admin/users/api"
import { DELETE_USER_MUTATION } from "~/screens/users/components/api"
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "~/shadcn/ui/alert-dialog"
import { Button } from "~/shadcn/ui/button"
import { Card, CardContent } from "~/shadcn/ui/card"
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "~/shadcn/ui/table"
import { Tooltip, TooltipContent, TooltipTrigger } from "~/shadcn/ui/tooltip"
import { ModalDialog } from "~/ui/ModalDialog"
import { InviteTeamMemberModal } from "./components/InviteTeamMemberModal"

const TEAM_MEMBER_FRAGMENT = gql(`
  fragment TeamMember on User {
    id
    firstName
    lastName
    email
    jobTitle
    agencyRole
    isOnboarded
  }
`)

const GET_TEAM_MEMBERS = gql(`
  query GetTeamMembers(
    $first: Int
    $after: String
    $sortField: UserSortFieldEnum
    $sortDirection: SortDirectionEnum
    $currentAgency: Boolean
  ) {
    users(
      first: $first
      after: $after
      sortField: $sortField
      sortDirection: $sortDirection
      currentAgency: $currentAgency
    ) {
      nodes {
        ...TeamMember
      }
    }
  }
`)

export const SettingsTeamScreen = () => {
  useHeaderText({ header: "Team", subheader: null })
  const currentUser = useCurrentUser()
  const [isInviteModalOpen, setIsInviteModalOpen] = useState(false)

  const { data, loading, error, refetch } = useQuery(GET_TEAM_MEMBERS, {
    variables: {
      first: 100,
      sortField: UserSortFieldEnum.UserName,
      sortDirection: SortDirectionEnum.Asc,
      currentAgency: true,
    },
  })

  const [resendUserInvite, { loading: resendLoading }] = useSafeMutation(
    ADMIN_RESEND_USER_INVITE_MUTATION
  )

  const [deleteUser] = useSafeMutation(DELETE_USER_MUTATION)
  const handleDeleteUser = async (userId: string) => {
    let result = await deleteUser({
      variables: {
        input: {
          id: userId,
        },
      },
    })
    if (result.errors) {
      displayErrors(result.errors)
      return
    }
    toast.success("User deleted successfully")
    refetch()
  }

  if (currentUser.agencyRole !== AgencyRoleEnum.AgencyAdmin) {
    return (
      <div className="h-full pb-4 relative">
        <Card className="absolute left-0 right-0 top-0 bottom-4 overflow-auto">
          <CardContent className="flex-1 p-6 pt-4">
            <div className="text-red-600">
              You do not have permission to access the team settings. Only
              agency admins can manage team members.
            </div>
          </CardContent>
        </Card>
      </div>
    )
  }

  const handleResendInvite = async (userId: string, isOnboarded: boolean) => {
    if (resendLoading || isOnboarded) return
    try {
      const result = await resendUserInvite({
        variables: {
          input: {
            id: userId,
          },
        },
      })
      if (result.errors) {
        displayErrors(result.errors)
      } else {
        toast.success("Invite resent successfully")
      }
    } catch (error) {
      console.error("Error resending invite:", error)
      toast.error("Failed to resend invite")
    }
  }

  const teamMembers =
    data?.users.nodes.map((node) => {
      const member = getFragmentData(TEAM_MEMBER_FRAGMENT, node)
      return {
        id: member.id,
        name: [member.firstName, member.lastName].filter(Boolean).join(" "),
        email: member.email,
        role:
          member.agencyRole === "AGENCY_ADMIN"
            ? "Agency admin"
            : member.agencyRole === "AGENT"
            ? "Agent"
            : "Unset",
        status: member.isOnboarded ? "Onboarded" : "Invite Sent",
        isOnboarded: member.isOnboarded,
        avatarUrl: null,
      }
    }) || []

  return (
    <div className="h-full pb-4 relative">
      <Card className="absolute left-0 right-0 top-0 bottom-4 overflow-auto">
        <CardContent className="flex-1 p-6 pt-4 flex flex-col">
          <div className="flex flex-col gap-4">
            <div className="flex justify-between items-center gap-4">
              <div className="text-lg font-semibold">Team Members</div>
              <Button
                variant="default"
                onClick={() => {
                  setIsInviteModalOpen(true)
                }}
              >
                + Invite User
              </Button>
            </div>
          </div>
          {error ? (
            <GraphqlError error={error} />
          ) : loading ? (
            <div className="mt-4 text-center">Loading...</div>
          ) : (
            <Card className="mt-4">
              <Table>
                <TableHeader>
                  <TableRow>
                    <TableHead>Name</TableHead>
                    <TableHead>Email</TableHead>
                    <TableHead>Role</TableHead>
                    <TableHead>Status</TableHead>
                    <TableHead></TableHead>
                  </TableRow>
                </TableHeader>
                <TableBody>
                  {teamMembers.map((member) => (
                    <TableRow key={member.id}>
                      <TableCell>{member.name}</TableCell>
                      <TableCell>{member.email}</TableCell>
                      <TableCell>{member.role}</TableCell>
                      <TableCell>
                        <span
                          className={`inline-flex items-center rounded-md px-2 py-1 text-xs font-medium ${
                            member.status === "Onboarded"
                              ? "bg-[#D3E9D7] text-[#458B47]"
                              : "bg-[#FCE1C8] text-[#EB7535]"
                          }`}
                        >
                          {member.status}
                        </span>
                      </TableCell>
                      <TableCell>
                        <div className="flex gap-2 items-center justify-end">
                          {!member.isOnboarded && (
                            <Tooltip>
                              <TooltipTrigger asChild>
                                <Button
                                  variant="ghost"
                                  onClick={() =>
                                    handleResendInvite(
                                      member.id,
                                      member.isOnboarded
                                    )
                                  }
                                  disabled={resendLoading}
                                >
                                  <img {...envelope} alt="" className="me-1" />
                                  Resend Invite
                                </Button>
                              </TooltipTrigger>
                              <TooltipContent>
                                <p>
                                  {resendLoading
                                    ? "Resending..."
                                    : "Resend Invite"}
                                </p>
                              </TooltipContent>
                            </Tooltip>
                          )}
                          <AlertDialog>
                            <Tooltip>
                              <TooltipTrigger asChild>
                                <AlertDialogTrigger asChild>
                                  <Button
                                    variant="ghost"
                                    className="text-redDark"
                                    disabled={member.id === currentUser.id}
                                  >
                                    <img {...trash} alt="" className="me-1" />
                                    Delete
                                  </Button>
                                </AlertDialogTrigger>
                              </TooltipTrigger>
                              <TooltipContent>
                                <p>
                                  {member.id === currentUser.id
                                    ? "Cannot delete yourself"
                                    : "Delete User"}
                                </p>
                              </TooltipContent>
                            </Tooltip>
                            <AlertDialogContent>
                              <AlertDialogHeader>
                                <AlertDialogTitle>
                                  Delete <code>{member.email}</code>?
                                </AlertDialogTitle>
                                <AlertDialogDescription>
                                  This user will no longer have access.
                                </AlertDialogDescription>
                              </AlertDialogHeader>
                              <AlertDialogFooter>
                                <AlertDialogCancel>Cancel</AlertDialogCancel>
                                <AlertDialogAction
                                  onClick={() => handleDeleteUser(member.id)}
                                >
                                  Delete
                                </AlertDialogAction>
                              </AlertDialogFooter>
                            </AlertDialogContent>
                          </AlertDialog>
                        </div>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Card>
          )}
        </CardContent>
      </Card>
      <ModalDialog
        open={isInviteModalOpen}
        onClose={() => setIsInviteModalOpen(false)}
        size="sm"
      >
        <InviteTeamMemberModal
          onClose={() => setIsInviteModalOpen(false)}
          onSuccess={() => refetch()}
        />
      </ModalDialog>
    </div>
  )
}

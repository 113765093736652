import { Outlet, matchPath, useLocation } from "react-router-dom"
import invariant from "tiny-invariant"
import {
  onboardingPersonalDetailsPath,
  onboardingAgencyDetailsPath,
  serverSignOutPath,
} from "~/common/paths"
import { cn } from "~/common/shadcn-utils"
import { MobileWarningCard } from "~/components/MobileWarning"

import ZanoLogoLight from "~/images/zano_logo_full-light.svg?react"
import PersonalDetailsIcon from "~/images/icons/personal-details.svg?react"
import AgencyDetailsIcon from "~/images/icons/agency-details.svg?react"
import CheckmarkIcon from "~/images/icons/checkmark.svg?react"
import CloverPrimary from "~/images/clover-primary.png"
import CloverSecondary from "~/images/clover-secondary.png"
import { useCurrentUser } from "~/auth/CurrentUserContext"
import { AgencyRoleEnum } from "~/__generated__/graphql"

const ONBOARDING_PROGRESSION = [
  onboardingPersonalDetailsPath.pattern,
  onboardingAgencyDetailsPath.pattern,
]

export const OnboardingLayout = () => {
  const { agencyRole } = useCurrentUser()
  const isAgencyAdmin = agencyRole === AgencyRoleEnum.AgencyAdmin

  return (
    <div className="min-h-screen flex flex-col md:flex-row">
      <div className="lg:hidden">
        <MobileWarningCard />
      </div>
      <div className="hidden lg:flex bg-defaultPrimary flex-col">
        <div className="py-4 px-16 bg-defaultPrimary flex flex-col">
          <ZanoLogoLight className="w-24 my-12" />

          <Item
            icon={<PersonalDetailsIcon />}
            heading="Your personal details"
            subheading="Personal details of user"
            step={onboardingPersonalDetailsPath.pattern}
          />
          {isAgencyAdmin && (
            <>
              <Separator />
              <Item
                icon={<AgencyDetailsIcon />}
                heading="Company details"
                subheading="Basic information about your company"
                step={onboardingAgencyDetailsPath.pattern}
              />
            </>
          )}
        </div>
        <div
          className="flex-grow flex flex-col justify-end mt-4 text-white py-8 px-16"
          style={{
            backgroundImage: `url(${CloverSecondary})`,
            backgroundPosition: "right top",
          }}
        >
          <a href={serverSignOutPath.pattern}>Logout</a>
        </div>
      </div>
      <div className="hidden lg:flex relative flex-1 justify-start items-stretch p-8 pt-16 bg-gray10 overflow-auto ">
        <div className="absolute top-0 right-0 h-full w-[272px] flex flex-col z-0">
          <div className="h-[300px]" />
          <div
            className="flex-grow"
            style={{
              backgroundImage: `url(${CloverPrimary})`,
              backgroundPosition: "right top",
              backgroundRepeat: "repeat-y",
            }}
          />
        </div>
        <div className="relative flex-1 z-10 flex justify-start items-start">
          <Outlet />
        </div>
      </div>
    </div>
  )
}

const Item = ({
  icon,
  heading,
  subheading,
  step,
}: {
  icon: React.ReactNode
  heading: string
  subheading: string
  step: (typeof ONBOARDING_PROGRESSION)[number]
}) => {
  const location = useLocation()
  const currentStep = ONBOARDING_PROGRESSION.find((pattern) =>
    matchPath(location.pathname, pattern)
  )

  invariant(currentStep, "current onboarding step not an expected step")

  const currentIndex = ONBOARDING_PROGRESSION.indexOf(currentStep)
  const itemIndex = ONBOARDING_PROGRESSION.indexOf(step)

  const isActive = currentIndex === itemIndex
  const isComplete = currentIndex > itemIndex

  return (
    <div className="text-white flex flex-row gap-8 items-center">
      <div
        className={cn({
          "rounded-full w-[59px] h-[59px] flex justify-center items-center bg-white":
            true,
          "bg-white text-defaultPrimary": isActive,
          "bg-opacity-20 text-white": !isActive,
        })}
      >
        {isComplete ? <CheckmarkIcon /> : icon}
      </div>
      <div>
        <div className="font-semibold text-xl">{heading}</div>
        <div className="opacity-70">{subheading}</div>
      </div>
    </div>
  )
}

const Separator = () => {
  return (
    <div className="h-[63px] w-[1px] bg-white bg-opacity-20 ml-[29px] my-4" />
  )
}

import { useNavigate } from "react-router-dom"
import { afterLoginPath } from "~/common/paths"
import { useSafeMutation } from "~/common/useSafeMutation"
import { UPDATE_USER_MUTATION } from "./api"

export const useOnCompleteOnboarding = () => {
  const navigate = useNavigate()
  const [runUpdateUser] = useSafeMutation(UPDATE_USER_MUTATION)

  const onCompleteOnboarding = async () => {
    const { data, errors } = await runUpdateUser({
      variables: {
        input: {
          onboarded: true,
        },
      },
    })
    if (errors) {
      console.error(errors)
    } else if (data) {
      navigate(afterLoginPath({}))
    } else {
      console.error("unexpected response", { data, errors })
    }
  }

  return { onCompleteOnboarding }
}

import { PencilIcon, Trash2Icon } from "lucide-react"
import { useState } from "react"
import toast from "react-hot-toast"
import { formatDistance } from "date-fns"
import { getFragmentData } from "~/__generated__"
import { UserSortFieldEnum } from "~/__generated__/graphql"
import { businessTypeName } from "~/common/enums"
import { useHeaderText } from "~/common/HeaderContext"
import { useSafeMutation } from "~/common/useSafeMutation"
import { displayErrors } from "~/common/validations"
import ImpersonateIcon from "~/images/icons/impersonate.svg?react"
import MailIcon from "~/images/icons/mail.svg?react"
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "~/shadcn/ui/alert-dialog"
import { Button } from "~/shadcn/ui/button"
import { Card, CardContent } from "~/shadcn/ui/card"
import { FloatingLabelInput } from "~/shadcn/ui/floating-label-input"
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "~/shadcn/ui/table"
import { Tooltip, TooltipContent, TooltipTrigger } from "~/shadcn/ui/tooltip"
import { Avatar } from "~/ui/Avatar"
import { InfiniteLoadMore } from "~/ui/InfiniteLoadMore"
import { ModalDialog } from "~/ui/ModalDialog"
import { friendlyNumber } from "~/util/numbers"
import { InviteUserButton } from "./components/InviteUserButton"
import {
  ADMIN_IMPERSONATE_USER_MUTATION,
  ADMIN_RESEND_USER_INVITE_MUTATION,
  ADMIN_USER_FRAGMENT,
} from "./api"
import { NewUserModal } from "./components/NewUserModal"
import { EditUserModal } from "./components/EditUserModal"
import { UserSortProvider } from "./components/UserSortContext"
import { useUsers } from "./useUsers"
import { SortableColumn } from "./components/SortableColumn"
import { DELETE_USER_MUTATION } from "~/screens/users/components/api"

export const AdminUsersScreen = () => {
  const {
    users,
    loading,
    refetch,
    sortField,
    sortDir,
    onSort,
    onLoadNextPage,
    pageInfo,
  } = useUsers()
  useHeaderText({ header: "Users", subheader: null })
  const [isNewUserModalOpen, setIsNewUserModalOpen] = useState(false)
  const [editingUser, setEditingUser] = useState<(typeof users)[0] | null>(null)

  const [deleteUser] = useSafeMutation(DELETE_USER_MUTATION)
  const handleDeleteUser = async (userId: string) => {
    let result = await deleteUser({
      variables: {
        input: {
          id: userId,
        },
      },
    })
    if (result.errors) {
      displayErrors(result.errors)
      return
    }
    toast.success("User deleted successfully")
    refetch()
  }

  const [resendUserInvite, { loading: resendLoading }] = useSafeMutation(
    ADMIN_RESEND_USER_INVITE_MUTATION
  )
  const handleResendInvite = async (userId: string, isOnboarded: boolean) => {
    if (resendLoading || isOnboarded) return
    try {
      const result = await resendUserInvite({
        variables: {
          input: {
            id: userId,
          },
        },
      })
      if (result.errors) {
        displayErrors(result.errors)
      } else {
        toast.success("Invite resent successfully")
      }
    } catch (error) {
      console.error("Error resending invite:", error)
      toast.error("Failed to resend invite")
    }
  }

  const [impersonateUser] = useSafeMutation(ADMIN_IMPERSONATE_USER_MUTATION)
  const handleImpersonateUser = async (userId: string) => {
    try {
      const result = await impersonateUser({
        variables: {
          input: {
            userId,
          },
        },
      })
      if (result.data?.impersonateUser?.success) {
        toast.success("Impersonation started")
        window.location.href = "/" // Redirect to the root path with a full page reload
      } else {
        toast.error(
          result.data?.impersonateUser?.errors?.[0] ||
            "Failed to impersonate user"
        )
      }
    } catch (error) {
      console.error("Error impersonating user:", error)
      toast.error("Failed to impersonate user")
    }
  }

  const handleEditUser = (user: (typeof users)[0]) => {
    setEditingUser(user)
  }

  return (
    <div className="h-full pb-4 relative">
      <Card className="absolute left-0 right-0 top-0 bottom-4 overflow-auto">
        <CardContent className="flex-1 p-6 pt-4 flex flex-col">
          <div className="flex flex-col gap-4">
            <div className="flex justify-between items-center gap-4">
              <FloatingLabelInput id="search" label="Search..." />
              <div className="flex flex-row gap-4">
                <Button
                  variant="outline"
                  onClick={() => {
                    setIsNewUserModalOpen(true)
                  }}
                >
                  + New User Manual
                </Button>
                <InviteUserButton />
              </div>
            </div>
          </div>
          <Card className="absolute top-[70px] bottom-4 left-6 right-6 flex-grow overflow-auto">
            <Table>
              <TableHeader>
                <UserSortProvider
                  sortField={sortField}
                  sortDir={sortDir}
                  onSort={onSort}
                >
                  <TableRow>
                    <TableHead sticky>
                      <SortableColumn field={UserSortFieldEnum.AgencyName}>
                        Agency
                      </SortableColumn>
                    </TableHead>
                    <TableHead sticky>
                      <SortableColumn field={UserSortFieldEnum.BusinessType}>
                        Business Type
                      </SortableColumn>
                    </TableHead>
                    <TableHead sticky>Plan</TableHead>
                    <TableHead sticky>
                      <SortableColumn field={UserSortFieldEnum.InfluencerCount}>
                        Influencers
                      </SortableColumn>
                    </TableHead>
                    <TableHead sticky>Casting Calls</TableHead>
                    <TableHead sticky>
                      <SortableColumn field={UserSortFieldEnum.UserName}>
                        Name
                      </SortableColumn>
                    </TableHead>
                    <TableHead sticky>
                      <SortableColumn field={UserSortFieldEnum.Email}>
                        Email
                      </SortableColumn>
                    </TableHead>
                    <TableHead sticky>
                      <SortableColumn field={UserSortFieldEnum.OnboardedAt}>
                        Onboarded?
                      </SortableColumn>
                    </TableHead>
                    <TableHead sticky>
                      <SortableColumn field={UserSortFieldEnum.LoginCount}>
                        Logins
                      </SortableColumn>
                    </TableHead>
                    <TableHead sticky>
                      <SortableColumn field={UserSortFieldEnum.LastActivityAt}>
                        Last Activity
                      </SortableColumn>
                    </TableHead>
                    <TableHead sticky></TableHead>
                    <TableHead sticky></TableHead>
                  </TableRow>
                </UserSortProvider>
              </TableHeader>
              <TableBody>
                {users.map((userF) => {
                  let user = getFragmentData(ADMIN_USER_FRAGMENT, userF)
                  return (
                    <TableRow key={user.id}>
                      <TableCell>
                        {user.agency && (
                          <div className="flex items-center gap-2">
                            <Avatar
                              src={user.agency?.logoThumbnailUrl}
                              alt={user.email || "avatar"}
                            />
                            {user.agency.name}
                          </div>
                        )}
                      </TableCell>
                      <TableCell>
                        {businessTypeName(user.agency?.businessType)}
                      </TableCell>
                      <TableCell></TableCell>
                      <TableCell className="text-right">
                        {friendlyNumber(user.influencerCount)}
                      </TableCell>
                      <TableCell />
                      <TableCell>
                        {user.firstName} {user.lastName}
                      </TableCell>
                      <TableCell>{user.email}</TableCell>
                      <TableCell>{user.isOnboarded ? "Yes" : "No"}</TableCell>
                      <TableCell>{user.loginCount}</TableCell>
                      <TableCell>
                        {user.lastActivityAt
                          ? formatDistance(
                              new Date(user.lastActivityAt),
                              new Date(),
                              {
                                addSuffix: true,
                              }
                            )
                          : "Never"}
                      </TableCell>
                      <TableCell>
                        <div className="flex gap-2 items-center">
                          <AlertDialog>
                            <Tooltip>
                              <TooltipTrigger asChild>
                                <AlertDialogTrigger asChild>
                                  <Button
                                    variant="ghost"
                                    size="icon"
                                    disabled={resendLoading || user.isOnboarded}
                                  >
                                    <MailIcon />
                                  </Button>
                                </AlertDialogTrigger>
                              </TooltipTrigger>
                              <TooltipContent>
                                <p>
                                  {resendLoading
                                    ? "Resending..."
                                    : user.isOnboarded
                                    ? "User already onboarded"
                                    : "Resend Invite"}
                                </p>
                              </TooltipContent>
                            </Tooltip>
                            <AlertDialogContent>
                              <AlertDialogHeader>
                                <AlertDialogTitle>
                                  Resend invite to <code>{user.email}</code>?
                                </AlertDialogTitle>
                                <AlertDialogDescription>
                                  This will send a new invitation email to the
                                  user.
                                </AlertDialogDescription>
                              </AlertDialogHeader>
                              <AlertDialogFooter>
                                <AlertDialogCancel>Cancel</AlertDialogCancel>
                                <AlertDialogAction
                                  onClick={() =>
                                    handleResendInvite(
                                      user.id,
                                      user.isOnboarded
                                    )
                                  }
                                >
                                  Resend Invite
                                </AlertDialogAction>
                              </AlertDialogFooter>
                            </AlertDialogContent>
                          </AlertDialog>
                          <AlertDialog>
                            <Tooltip>
                              <TooltipTrigger asChild>
                                <AlertDialogTrigger asChild>
                                  <Button variant="ghost" size="icon">
                                    <div className="sr-only">Impersonate</div>
                                    <ImpersonateIcon />
                                  </Button>
                                </AlertDialogTrigger>
                              </TooltipTrigger>
                              <TooltipContent>
                                <p>Impersonate User</p>
                              </TooltipContent>
                            </Tooltip>
                            <AlertDialogContent>
                              <AlertDialogHeader>
                                <AlertDialogTitle>
                                  Impersonate <code>{user.email}</code>?
                                </AlertDialogTitle>
                                <AlertDialogDescription>
                                  You will be logged in as this user. To return
                                  to your admin account, you'll need to log out.
                                </AlertDialogDescription>
                              </AlertDialogHeader>
                              <AlertDialogFooter>
                                <AlertDialogCancel>Cancel</AlertDialogCancel>
                                <AlertDialogAction
                                  onClick={() => handleImpersonateUser(user.id)}
                                >
                                  Impersonate
                                </AlertDialogAction>
                              </AlertDialogFooter>
                            </AlertDialogContent>
                          </AlertDialog>
                          <Tooltip>
                            <TooltipTrigger asChild>
                              <Button
                                variant="ghost"
                                size="icon"
                                onClick={() => handleEditUser(user)}
                              >
                                <div className="sr-only">Edit</div>
                                <PencilIcon />
                              </Button>
                            </TooltipTrigger>
                            <TooltipContent>
                              <p>Edit User</p>
                            </TooltipContent>
                          </Tooltip>
                          <AlertDialog>
                            <Tooltip>
                              <TooltipTrigger asChild>
                                <AlertDialogTrigger asChild>
                                  <Button variant="ghost" size="icon">
                                    <div className="sr-only">Delete</div>
                                    <Trash2Icon />
                                  </Button>
                                </AlertDialogTrigger>
                              </TooltipTrigger>
                              <TooltipContent>
                                <p>Delete User</p>
                              </TooltipContent>
                            </Tooltip>
                            <AlertDialogContent>
                              <AlertDialogHeader>
                                <AlertDialogTitle>
                                  Delete <code>{user.email}</code>?
                                </AlertDialogTitle>
                                <AlertDialogDescription>
                                  This user will no longer have access.
                                </AlertDialogDescription>
                              </AlertDialogHeader>
                              <AlertDialogFooter>
                                <AlertDialogCancel>Cancel</AlertDialogCancel>
                                <AlertDialogAction
                                  onClick={() => handleDeleteUser(user.id)}
                                >
                                  Delete
                                </AlertDialogAction>
                              </AlertDialogFooter>
                            </AlertDialogContent>
                          </AlertDialog>
                        </div>
                      </TableCell>
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
            <InfiniteLoadMore
              onEndReached={onLoadNextPage}
              canLoadMore={!loading && !!pageInfo?.hasNextPage}
              loading={loading && users.length > 0}
              className="p-4"
            />
            {loading && <div>Loading...</div>}
          </Card>
        </CardContent>
      </Card>
      <ModalDialog
        open={isNewUserModalOpen}
        onClose={() => setIsNewUserModalOpen(false)}
      >
        <NewUserModal
          onClose={() => setIsNewUserModalOpen(false)}
          onUserCreated={() => {
            refetch({})
          }}
        />
      </ModalDialog>
      <ModalDialog open={!!editingUser} onClose={() => setEditingUser(null)}>
        {editingUser && (
          <EditUserModal
            user={getFragmentData(ADMIN_USER_FRAGMENT, editingUser)}
            onClose={() => setEditingUser(null)}
            onUserUpdated={() => {
              refetch({})
            }}
          />
        )}
      </ModalDialog>
    </div>
  )
}

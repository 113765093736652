import { FullInfluencerFragment, PlatformEnum } from "~/__generated__/graphql"
import {
  categoryName,
  collaborationTypeNames,
  creatorTypeName,
  exclusivityName,
} from "~/common/enums"
import { SocialMediaIcon } from "~/common/SocialMediaIcons"
import { Avatar } from "~/ui/Avatar"
import { Tag } from "~/ui/Tag"
import { CopyButton } from "~/ui/CopyButton"

const ClickableSocialMediaIcon = ({
  url,
  platform,
}: {
  url: string
  platform: PlatformEnum
}) => {
  return (
    <a href={url} target="_blank" rel="noreferrer">
      <SocialMediaIcon platform={platform} />
    </a>
  )
}

const countryTagName = (influencer: FullInfluencerFragment) => {
  if (influencer.countryData) {
    return `${influencer.countryData.emojiFlag} ${influencer.countryData.name}`
  }
  return influencer.countryCode || ""
}

export const Heading = ({
  influencer,
}: {
  influencer: FullInfluencerFragment
}) => {
  return (
    <div className="flex flex-row gap-4 mb-4">
      <div>
        <Avatar
          src={influencer.profileImageThumbnailUrl}
          alt={influencer.name}
          size="lg"
        />
      </div>
      <div className="flex-grow flex flex-col gap-2">
        <div className="font-header font-bold text-2xl">{influencer.name}</div>
        <div className="flex flex-row gap-2 items-center">
          {influencer.tikTokProfile?.url && (
            <ClickableSocialMediaIcon
              url={influencer.tikTokProfile.url}
              platform={PlatformEnum.Tiktok}
            />
          )}
          {influencer.instagramProfile?.url && (
            <ClickableSocialMediaIcon
              url={influencer.instagramProfile.url}
              platform={PlatformEnum.Instagram}
            />
          )}
          {influencer.youtubeProfile?.url && (
            <ClickableSocialMediaIcon
              url={influencer.youtubeProfile.url}
              platform={PlatformEnum.Youtube}
            />
          )}
          {influencer.twitchProfile?.url && (
            <ClickableSocialMediaIcon
              url={influencer.twitchProfile.url}
              platform={PlatformEnum.Twitch}
            />
          )}
        </div>
        <div className="text-defaultSecondary">
          {collaborationTypeNames(influencer.collaborationTypes).join(" • ")}
        </div>
        <div className="flex flex-wrap gap-2">
          {influencer.countryCode && (
            <Tag variant="country" text={countryTagName(influencer)} />
          )}
          {influencer.creatorTypes?.map((creatorType) => (
            <Tag
              key={creatorType}
              variant="creatorType"
              text={creatorTypeName(creatorType)}
            />
          ))}
          {influencer.category && (
            <Tag variant="category" text={categoryName(influencer.category)} />
          )}
        </div>
      </div>
      <div className="text-right flex flex-col gap-1">
        <div className="font-header font-bold text-2xl">
          {influencer.agency?.name}
        </div>
        <div className="text-defaultSecondary">
          {exclusivityName(influencer.exclusivity)}
        </div>
        <div className="text-defaultSecondary">{influencer.agentName}</div>
        {influencer.agentPhone && (
          <div className="text-defaultSecondary">{influencer.agentPhone}</div>
        )}
        {influencer.agentEmail && (
          <div className="flex justify-end">
            <CopyButton
              label={influencer.agentEmail}
              textToCopy={influencer.agentEmail}
              successMessage={`"${influencer.agentEmail}" copied to clipboard`}
            />
          </div>
        )}
      </div>
    </div>
  )
}

import { useQuery } from "@apollo/client"
import { useEffect, useMemo, useState } from "react"
import {
  InfluencerSortFieldEnum,
  SortDirectionEnum,
} from "~/__generated__/graphql"
import { ROSTER_DATABASE_QUERY_DOCUMENT } from "./api"
import { SearchValues, searchValuesToGraphql } from "./schema"

const defaultSorts: Record<InfluencerSortFieldEnum, SortDirectionEnum> = {
  [InfluencerSortFieldEnum.Name]: SortDirectionEnum.Asc,
  [InfluencerSortFieldEnum.AgencyName]: SortDirectionEnum.Asc,
  [InfluencerSortFieldEnum.AgentEmail]: SortDirectionEnum.Asc,
  [InfluencerSortFieldEnum.Category]: SortDirectionEnum.Asc,
  [InfluencerSortFieldEnum.Country]: SortDirectionEnum.Asc,
  [InfluencerSortFieldEnum.Exclusivity]: SortDirectionEnum.Asc,
  [InfluencerSortFieldEnum.TikTokFollowers]: SortDirectionEnum.Desc,
  [InfluencerSortFieldEnum.InstagramFollowers]: SortDirectionEnum.Desc,
  [InfluencerSortFieldEnum.YoutubeSubscribers]: SortDirectionEnum.Desc,
  [InfluencerSortFieldEnum.TwitchSubscribers]: SortDirectionEnum.Desc,
}

const DEFAULT_SORT_FIELD = InfluencerSortFieldEnum.Category

export type UseInfluencersResult = ReturnType<typeof useInfluencers>

export const useInfluencers = ({ myRoster }: { myRoster?: boolean }) => {
  const [lastSearchField, setLastSearchField] = useState<string | undefined>()
  const [searchValues, setSearchValues] = useState<SearchValues>({})
  const [sortField, setSortField] = useState<
    InfluencerSortFieldEnum | undefined
  >(DEFAULT_SORT_FIELD)
  const [sortDir, setSortDir] = useState<SortDirectionEnum>(
    SortDirectionEnum.Asc
  )

  // Filtering by a query string uses its own ordering by default based on match quality,
  // so let's clear our custom one. Clicking a column again will reintroduce a custom order.
  useEffect(() => {
    if (searchValues.query !== lastSearchField) {
      if (searchValues.query) {
        setSortField(undefined)
      } else {
        setSortField(DEFAULT_SORT_FIELD)
        setSortDir(defaultSorts[DEFAULT_SORT_FIELD])
      }
      setLastSearchField(searchValues.query)
    }
  }, [lastSearchField, searchValues.query])

  const queryResult = useQuery(ROSTER_DATABASE_QUERY_DOCUMENT, {
    variables: {
      myRoster: myRoster,
      filters: searchValuesToGraphql(searchValues),
      sortField: sortField,
      sortDirection: sortDir,
      first: 50,
      influencersCursor: null,
    },
    notifyOnNetworkStatusChange: true,
  })

  const influencers =
    queryResult.data?.influencers.edges.map((e) => e.node) ?? []

  const onSort = (field: InfluencerSortFieldEnum) => {
    if (field === sortField) {
      setSortDir(
        sortDir === SortDirectionEnum.Asc
          ? SortDirectionEnum.Desc
          : SortDirectionEnum.Asc
      )
    } else {
      setSortField(field)
      setSortDir(defaultSorts[field])
    }
  }

  const onLoadNextPage = () => {
    queryResult.fetchMore({
      variables: {
        influencersCursor: queryResult?.data?.influencers.pageInfo.endCursor,
      },
    })
  }

  const isFiltering = useMemo(() => {
    return Object.values(searchValues).some((v) => v)
  }, [searchValues])

  return {
    ...queryResult,
    influencers,
    isFiltering,
    setSearchValues,
    sortField,
    sortDir,
    onSort,
    onLoadNextPage,
    pageInfo: queryResult?.data?.influencers?.pageInfo,
  }
}

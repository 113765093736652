import { useQuery } from "@apollo/client"
import { ExternalLink, Trash2 } from "lucide-react"
import { useCallback, useEffect, useState } from "react"
import invariant from "tiny-invariant"
import { gql } from "~/__generated__"
import { InfluencerSortFieldEnum } from "~/__generated__/graphql"
import { categoryName, creatorTypeNames, exclusivityName } from "~/common/enums"
import { cn } from "~/common/shadcn-utils"
import {
  InstagramIcon,
  TikTokIcon,
  TwitchIcon,
  YouTubeIcon,
} from "~/common/SocialMediaIcons"
import { useSafeMutation } from "~/common/useSafeMutation"
import { Alert, AlertDescription, AlertTitle } from "~/shadcn/ui/alert"
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from "~/shadcn/ui/alert-dialog"
import { Button } from "~/shadcn/ui/button"
import { Card } from "~/shadcn/ui/card"
import { Checkbox } from "~/shadcn/ui/checkbox"
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "~/shadcn/ui/table"
import { Avatar } from "~/ui/Avatar"
import { ErrorBox } from "~/ui/Error"
import { InfiniteLoadMore } from "~/ui/InfiniteLoadMore"
import {
  LoadingIndicator,
  LoadingIndicatorCentered,
} from "~/ui/LoadingIndicator"
import { friendlyNumber } from "~/util/numbers"
import { trackEvent } from "~/util/tracking"
import { InfluencerSortProvider } from "../../components/InfluencerSortContext"
import { SearchBar } from "../../components/SearchBar"
import { SortableColumn } from "../../components/SortableColumn"
import { UseInfluencersResult } from "../../useInfluencers"
import { NewInfluencerButton } from "../components/NewInfluencerButton"
import { useInfluencerActions } from "./InfluencerActionsContext"
import { InfluencerDialogType } from "./InfluencerDialogType"

const INFLUENCER_CSV_EXPORT_CREATE_MUTATION = gql(`
  mutation InfluencerCsvExportCreate(
    $input: InfluencerCsvExportCreateInput!
  ) {
    influencerCsvExportCreate(input: $input) {
      influencerCsvExport {
        id
      }
    }
  }
`)

const INFLUENCER_CSV_EXPORT_QUERY = gql(`
  query InfluencerCsvExport($id: ID!) {
    influencerCsvExport(id: $id) {
      id
      isGenerated
      downloadUrl
      failure
    }
  }
`)

const INFLUENCER_BATCH_DELETE_MUTATION = gql(`
  mutation InfluencerBatchDelete($input: InfluencerBatchDeleteInput!) {
    influencerBatchDelete(input: $input) {
      discardedInfluencerIds
    }
  }
`)

export const InfluencersTable = ({
  useInfluencersResult,
}: {
  useInfluencersResult: UseInfluencersResult
}) => {
  const [selectedInfluencers, setSelectedInfluencers] = useState<string[]>([])
  const [isSelectAllChecked, setIsSelectAllChecked] = useState(false)
  const [isLoadingAll, setIsLoadingAll] = useState(false)
  const [exportState, setExportState] = useState<
    "idle" | "exporting" | "ready" | "failed"
  >("idle")
  const [exportId, setExportId] = useState<string | null>(null)
  const [exportFailureMessage, setExportFailureMessage] = useState<
    string | null
  >(null)
  const [deleteState, setDeleteState] = useState<
    "idle" | "deleting" | "deleted" | "failed"
  >("idle")
  const [deleteFailureMessage, setDeleteFailureMessage] = useState<
    string | null
  >(null)
  const [isDeleteConfirmOpen, setIsDeleteConfirmOpen] = useState(false)

  const [createInfluencerCsvExport] = useSafeMutation(
    INFLUENCER_CSV_EXPORT_CREATE_MUTATION
  )

  const [batchDeleteInfluencers] = useSafeMutation(
    INFLUENCER_BATCH_DELETE_MUTATION
  )

  const { data: exportData, stopPolling } = useQuery(
    INFLUENCER_CSV_EXPORT_QUERY,
    {
      variables: { id: exportId || "" },
      skip: !exportId,
      pollInterval: 500,
    }
  )

  useEffect(() => {
    if (exportData?.influencerCsvExport?.isGenerated) {
      stopPolling()
      setExportState("ready")
    } else if (exportData?.influencerCsvExport?.failure) {
      stopPolling()
      setExportState("failed")
      setExportFailureMessage(exportData.influencerCsvExport.failure)
    }
  }, [exportData, stopPolling])

  const toggleInfluencer = useCallback((influencerId: string) => {
    setSelectedInfluencers((prev) =>
      prev.includes(influencerId)
        ? prev.filter((id) => id !== influencerId)
        : [...prev, influencerId]
    )
  }, [])

  const handleExport = useCallback(async () => {
    setExportState("exporting")
    setExportFailureMessage(null)
    try {
      const { data } = await createInfluencerCsvExport({
        variables: {
          input: {
            influencerCsvExportInput: {
              userIds: selectedInfluencers,
            },
          },
        },
      })
      if (data?.influencerCsvExportCreate?.influencerCsvExport?.id) {
        setExportId(data.influencerCsvExportCreate.influencerCsvExport.id)
      } else {
        throw new Error("Export failed: No export ID returned")
      }
    } catch (error) {
      console.error("Export failed:", error)
      setExportState("failed")
      setExportFailureMessage(
        "An error occurred while initiating the export. Please try again."
      )
    }
  }, [createInfluencerCsvExport, selectedInfluencers])

  const {
    influencers,
    data,
    error,
    fetchMore,
    loading,
    setSearchValues,
    sortField,
    sortDir,
    onSort,
    refetch,
    onLoadNextPage,
    pageInfo,
  } = useInfluencersResult
  const { setOpenDialogType, setActiveInfluencerId } = useInfluencerActions()

  const handleDelete = useCallback(async () => {
    setDeleteState("deleting")
    setDeleteFailureMessage(null)
    try {
      const { data } = await batchDeleteInfluencers({
        variables: {
          input: {
            influencerIds: selectedInfluencers,
          },
        },
      })
      if (data?.influencerBatchDelete?.discardedInfluencerIds) {
        setDeleteState("deleted")
        setSelectedInfluencers([])
        // Refetch the influencers list
        await refetch()
      } else {
        throw new Error("Delete failed: No deleted IDs returned")
      }
    } catch (error) {
      console.error("Delete failed:", error)
      setDeleteState("failed")
      setDeleteFailureMessage(
        "An error occurred while deleting the influencers. Please try again."
      )
    } finally {
      // Reset the delete state to "idle" after a short delay
      setTimeout(() => setDeleteState("idle"), 2000)
    }
  }, [batchDeleteInfluencers, selectedInfluencers, refetch])

  const loadAllData = useCallback(async () => {
    console.log("loadAllData", data?.influencers)
    if (!data?.influencers.pageInfo.hasNextPage) return

    let hasNextPage = true
    let endCursor = data.influencers.pageInfo.endCursor

    while (hasNextPage) {
      const result = await fetchMore({
        variables: {
          influencersCursor: endCursor,
        },
      })

      hasNextPage = result.data.influencers.pageInfo.hasNextPage
      endCursor = result.data.influencers.pageInfo.endCursor
    }
  }, [data, fetchMore])

  useEffect(() => {
    if (isSelectAllChecked && !isLoadingAll && data?.influencers) {
      setSelectedInfluencers(
        data.influencers.edges.map((edge) => edge.node.id!)
      )
    }
  }, [isSelectAllChecked, isLoadingAll, data?.influencers])

  useEffect(() => {
    if (
      isLoadingAll &&
      data?.influencers &&
      !data.influencers.pageInfo.hasNextPage
    ) {
      setIsLoadingAll(false)
    }
  }, [isLoadingAll, data?.influencers])

  return (
    <>
      <div className="flex flex-col gap-4">
        <div className="flex justify-between items-center gap-4">
          <SearchBar setSearchValues={setSearchValues} myRoster={true} />
          <NewInfluencerButton
            onManualEntryClick={() => {
              setOpenDialogType(InfluencerDialogType.NewInfluencerManual)
              trackEvent("new_influencer", {})
            }}
            onBulkUploadClick={() => {
              setOpenDialogType(InfluencerDialogType.BulkUpload)
              trackEvent("bulk_upload", {})
            }}
          />
        </div>
        <div className="flex flex-col gap-4">
          {selectedInfluencers.length > 0 && (
            <div className="flex gap-4">
              <Button
                variant="outline"
                onClick={handleExport}
                disabled={
                  selectedInfluencers.length === 0 || exportState !== "idle"
                }
                className="px-4"
              >
                <ExternalLink className="mr-2 h-4 w-4" />
                Export ({selectedInfluencers.length.toLocaleString()})
              </Button>
              <Button
                variant="ghost"
                onClick={() => setIsDeleteConfirmOpen(true)}
                disabled={
                  selectedInfluencers.length === 0 || deleteState === "deleting"
                }
                className="px-4 text-redDark hover:text-redDark"
              >
                <Trash2 className="mr-2 h-4 w-4" />
                Delete ({selectedInfluencers.length.toLocaleString()})
              </Button>
            </div>
          )}
          {exportState === "failed" && exportFailureMessage && (
            <Alert variant="destructive">
              <AlertTitle>Export Failed</AlertTitle>
              <AlertDescription>{exportFailureMessage}</AlertDescription>
            </Alert>
          )}
          {deleteState === "failed" && deleteFailureMessage && (
            <Alert variant="destructive">
              <AlertTitle>Delete Failed</AlertTitle>
              <AlertDescription>{deleteFailureMessage}</AlertDescription>
            </Alert>
          )}
        </div>
      </div>
      {!data && loading ? (
        <LoadingIndicatorCentered />
      ) : error || !data ? (
        <ErrorBox message="Error loading influencers." />
      ) : (
        <Card
          className={cn(
            "absolute bottom-4 left-6 right-6 flex-grow overflow-auto",
            selectedInfluencers.length === 0 ? "top-[70px]" : "top-[130px]"
          )}
        >
          <Table>
            <TableHeader>
              <InfluencerSortProvider
                sortField={sortField}
                sortDir={sortDir}
                onSort={onSort}
              >
                <TableRow>
                  <TableHead sticky className="w-[50px]">
                    <Checkbox
                      checked={isSelectAllChecked}
                      onCheckedChange={(checked) => {
                        setIsSelectAllChecked(!!checked)
                        if (checked) {
                          setIsLoadingAll(true)
                          loadAllData()
                        } else {
                          setSelectedInfluencers([])
                        }
                      }}
                    />
                  </TableHead>
                  <TableHead sticky>
                    <SortableColumn field={InfluencerSortFieldEnum.Name}>
                      Name
                    </SortableColumn>
                  </TableHead>
                  <TableHead sticky>
                    <SortableColumn field={InfluencerSortFieldEnum.Category}>
                      Category
                    </SortableColumn>
                  </TableHead>
                  <TableHead sticky>
                    <SortableColumn field={InfluencerSortFieldEnum.Country}>
                      Country
                    </SortableColumn>
                  </TableHead>
                  <TableHead sticky>
                    <SortableColumn
                      field={InfluencerSortFieldEnum.TikTokFollowers}
                    >
                      <div className="flex gap-1 items-center">
                        <TikTokIcon className="w-[18px] h-[20px]" />
                        TT
                      </div>
                    </SortableColumn>
                  </TableHead>
                  <TableHead sticky>
                    <SortableColumn
                      field={InfluencerSortFieldEnum.InstagramFollowers}
                    >
                      <div className="flex gap-1 items-center">
                        <InstagramIcon className="w-[18px] h-[18px]" />
                        IG
                      </div>
                    </SortableColumn>
                  </TableHead>
                  <TableHead sticky>
                    <SortableColumn
                      field={InfluencerSortFieldEnum.YoutubeSubscribers}
                    >
                      <div className="flex gap-1 items-center">
                        <YouTubeIcon className="w-[19px] h-[13px]" />
                        YT
                      </div>
                    </SortableColumn>
                  </TableHead>
                  <TableHead sticky>
                    <SortableColumn
                      field={InfluencerSortFieldEnum.TwitchSubscribers}
                    >
                      <div className="flex gap-1 items-center">
                        <TwitchIcon className="w-[17px] h-[18px]" />
                        TW
                      </div>
                    </SortableColumn>
                  </TableHead>
                  <TableHead sticky>Creator Type</TableHead>
                  <TableHead sticky>
                    <SortableColumn field={InfluencerSortFieldEnum.Exclusivity}>
                      Exclusivity
                    </SortableColumn>
                  </TableHead>
                </TableRow>
              </InfluencerSortProvider>
            </TableHeader>
            <TableBody>
              {influencers
                .filter((influencer) => !influencer.deleted)
                .map((influencer) => (
                  <TableRow key={influencer.id} className="cursor-pointer">
                    <TableCell
                      className="w-[50px]"
                      onClick={(e) => e.stopPropagation()}
                    >
                      <Checkbox
                        checked={selectedInfluencers.includes(influencer.id!)}
                        onCheckedChange={() => toggleInfluencer(influencer.id!)}
                      />
                    </TableCell>
                    <TableCell
                      onClick={() => {
                        invariant(influencer.id, "Influencer ID is required")
                        setActiveInfluencerId(influencer.id)
                        setOpenDialogType(InfluencerDialogType.ViewInfluencer)
                      }}
                    >
                      <div className="flex flex-row items-center gap-2">
                        <Avatar
                          src={influencer.profileImageThumbnailUrl}
                          alt={influencer.name}
                        />
                        {influencer.name}
                      </div>
                    </TableCell>
                    <TableCell>{categoryName(influencer.category)}</TableCell>
                    <TableCell>{influencer.countryData?.name}</TableCell>
                    <TableCell>
                      {friendlyNumber(influencer.tikTokProfile?.followers)}
                    </TableCell>
                    <TableCell>
                      {friendlyNumber(influencer.instagramProfile?.followers)}
                    </TableCell>
                    <TableCell>
                      {friendlyNumber(influencer.youtubeProfile?.subscribers)}
                    </TableCell>
                    <TableCell>
                      {friendlyNumber(influencer.twitchProfile?.subscribers)}
                    </TableCell>
                    <TableCell>
                      {creatorTypeNames(influencer.creatorTypes).join(", ")}
                    </TableCell>
                    <TableCell>
                      {exclusivityName(influencer.exclusivity)}
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
          <InfiniteLoadMore
            onEndReached={onLoadNextPage}
            canLoadMore={!loading && !!pageInfo?.hasNextPage}
            loading={loading && influencers.length > 0}
            className="p-4"
          />
          {loading && <div>Loading...</div>}
        </Card>
      )}

      <AlertDialog
        open={exportState !== "idle"}
        onOpenChange={() =>
          (exportState === "ready" || exportState === "failed") &&
          setExportState("idle")
        }
      >
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>
              {exportState === "exporting"
                ? "Generating CSV Export"
                : exportState === "failed"
                ? "CSV Export Failed"
                : "CSV Export Ready"}
            </AlertDialogTitle>
            <AlertDialogDescription>
              {exportState === "exporting" ? (
                <div className="flex items-center">
                  <LoadingIndicator size="small" className="mr-2" />
                  Your CSV export is being generated. This may take a few
                  moments.
                </div>
              ) : exportState === "failed" ? (
                exportData?.influencerCsvExport?.failure ||
                "An error occurred during the export process."
              ) : (
                "Your CSV export has been generated and is now ready for download."
              )}
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            {exportState === "ready" && (
              <>
                <AlertDialogAction
                  onClick={() => {
                    if (exportData?.influencerCsvExport?.downloadUrl) {
                      window.open(
                        exportData.influencerCsvExport.downloadUrl,
                        "_blank"
                      )
                    }
                  }}
                >
                  Download
                </AlertDialogAction>
                <AlertDialogAction onClick={() => setExportState("idle")}>
                  Close
                </AlertDialogAction>
              </>
            )}
            {(exportState === "exporting" || exportState === "failed") && (
              <AlertDialogAction onClick={() => setExportState("idle")}>
                Close
              </AlertDialogAction>
            )}
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
      <AlertDialog
        open={isDeleteConfirmOpen}
        onOpenChange={setIsDeleteConfirmOpen}
      >
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>Confirm Deletion</AlertDialogTitle>
            <AlertDialogDescription>
              Are you sure you want to delete {selectedInfluencers.length}{" "}
              influencer(s)? This action cannot be undone.
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel>Cancel</AlertDialogCancel>
            <AlertDialogAction onClick={handleDelete}>
              {deleteState === "deleting" ? "Deleting..." : "Delete"}
            </AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  )
}

import { zodResolver } from "@hookform/resolvers/zod"
import { useForm } from "react-hook-form"
import toast from "react-hot-toast"
import invariant from "tiny-invariant"
import { z } from "zod"
import { gql } from "~/__generated__"
import { useSafeMutation } from "~/common/useSafeMutation"
import {
  displayErrors,
  getGraphqlErrors,
  isValidationError,
} from "~/common/validations"
import { Button } from "~/shadcn/ui/button"
import { FloatingLabelInput } from "~/shadcn/ui/floating-label-input"
import { Form, FormField, FormItem, FormMessage } from "~/shadcn/ui/form"
import { ModalDialogHeader } from "~/ui/ModalDialog"

const USER_INVITE_TO_AGENCY_MUTATION = gql(`
  mutation UserInviteToAgency($input: UserInviteToAgencyInput!) {
    userInviteToAgency(input: $input) {
      user {
        id
        email
      }
    }
  }
`)

const formSchema = z.object({
  email: z.string().email("Invalid email address"),
})

type FormValues = z.infer<typeof formSchema>

type Props = {
  onClose: () => void
  onSuccess?: () => void
}

export const InviteTeamMemberModal = ({ onClose, onSuccess }: Props) => {
  const form = useForm<FormValues>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      email: "",
    },
  })

  const [inviteUser, { loading }] = useSafeMutation(
    USER_INVITE_TO_AGENCY_MUTATION
  )

  const onSubmit = async (values: FormValues) => {
    const result = await inviteUser({
      variables: {
        input: {
          email: values.email,
        },
      },
    })

    if (result.errors) {
      displayErrors(result.errors)

      let errors = getGraphqlErrors(result.errors)
      invariant(errors, "Expected errors")

      for (let error of errors) {
        if (isValidationError(error)) {
          if (
            error.extensions.validationErrors.some((e) => e.type === "taken")
          ) {
            form.setError("email", {
              type: "manual",
              message:
                "Sorry, that user exists in our system already and cannot be invited",
            })
          }
        }
      }
      return
    }

    toast.success("Team member invited successfully")
    onClose()
    onSuccess?.()
  }

  return (
    <>
      <ModalDialogHeader
        title="Invite a team member"
        subtitle="Send an email invite to a new user."
      />
      <Form {...form}>
        <form
          onSubmit={form.handleSubmit(onSubmit)}
          className="flex flex-col gap-4"
        >
          <FormField
            control={form.control}
            name="email"
            render={({ field }) => (
              <FormItem>
                <FloatingLabelInput
                  {...field}
                  id="email"
                  label="Email Address"
                  type="email"
                  data-1p-ignore
                />
                <FormMessage />
              </FormItem>
            )}
          />
          <div className="flex justify-between">
            <Button
              type="button"
              onClick={onClose}
              variant="linkSecondary"
              size="none"
            >
              Cancel
            </Button>
            <Button type="submit" disabled={loading}>
              {loading ? "Sending..." : "Send Invite"}
            </Button>
          </div>
        </form>
      </Form>
    </>
  )
}

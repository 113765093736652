import tiktok from "~/images/icons/tiktok"
import instagram from "~/images/icons/instagram"
import youtube from "~/images/icons/youtube"
import twitch from "~/images/icons/twitch"
import { PlatformEnum } from "~/__generated__/graphql"

interface ImgWrapperProps extends React.ImgHTMLAttributes<HTMLImageElement> {}

export const platformLogoMap: Record<PlatformEnum, any> = {
  [PlatformEnum.Instagram]: instagram,
  [PlatformEnum.Tiktok]: tiktok,
  [PlatformEnum.Youtube]: youtube,
  [PlatformEnum.Twitch]: twitch,
}

export const TikTokIcon = (props: ImgWrapperProps) => (
  <img {...tiktok} alt="TikTok Logo" {...props} />
)

export const InstagramIcon = (props: ImgWrapperProps) => (
  <img {...instagram} alt="Instagram Logo" {...props} />
)

export const YouTubeIcon = (props: ImgWrapperProps) => (
  <img {...youtube} alt="YouTube Logo" {...props} />
)

export const TwitchIcon = (props: ImgWrapperProps) => (
  <img {...twitch} alt="Twitch Logo" {...props} />
)

export const SocialMediaIcon = (
  props: ImgWrapperProps & { platform: PlatformEnum }
) => {
  const platform = props.platform

  switch (platform) {
    case PlatformEnum.Tiktok:
      return <TikTokIcon {...props} />
    case PlatformEnum.Instagram:
      return <InstagramIcon {...props} />
    case PlatformEnum.Youtube:
      return <YouTubeIcon {...props} />
    case PlatformEnum.Twitch:
      return <TwitchIcon {...props} />
  }
}

import { useNavigate, Link } from "react-router-dom"
import { useCurrentUser } from "~/auth/CurrentUserContext"
import { useHeaderContext } from "~/common/HeaderContext"
import {
  myRosterPath,
  serverSignOutPath,
  settingsAccountPath,
} from "~/common/paths"
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "~/shadcn/ui/dropdown-menu"
import { Avatar } from "~/ui/Avatar"
import { H1 } from "~/ui/typography"

import AccountIcon from "~/images/icons/account.svg?react"
import LogoutIcon from "~/images/icons/logout.svg?react"
import { Alert, AlertDescription } from "~/shadcn/ui/alert"
import Text from "~/ui/typography"

import WarningIcon from "~/images/icons/warning.svg?react"
import { Button } from "~/shadcn/ui/button"

export const HeadingBar = () => {
  const user = useCurrentUser()
  const { headerText, subheaderText, headerBar } = useHeaderContext()
  const navigate = useNavigate()

  return (
    <div className="flex-grow flex flex-row justify-between items-center gap-4">
      <div>
        <H1>{headerText}</H1>
        {subheaderText && <p className="text-gray50">{subheaderText}</p>}
      </div>
      {!user.canIndexRosterDatabase.value && (
        <div>
          <Alert variant="warning">
            <AlertDescription>
              <div className="flex flex-row gap-3 items-center">
                <WarningIcon />
                <Text variant="body3" className="text-yellowDark">
                  Important: Final Step, you’re almost done!
                  <br />
                  Head to the{" "}
                  <Link to={myRosterPath({})}>
                    <Button
                      variant="linkSecondary"
                      size="none"
                      className="text-yellowDark"
                    >
                      My Roster page
                    </Button>
                  </Link>{" "}
                  and add your influencers to start landing those collaboration
                  deals!
                </Text>
              </div>
            </AlertDescription>
          </Alert>
        </div>
      )}
      {headerBar}
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <div className="flex flex-row items-center gap-2">
            <Avatar
              src={user.agency?.logoThumbnailUrl}
              alt={`Agency logo for ${user.agency?.name}`}
              size="md"
            />
            <div className="hidden lg:block">
              <div className="font-bold text-hoveredSecondary">
                {user.firstName} {user.lastName}
              </div>
              {user.agency && (
                <div className="text-gray80 -mt-1">{user.agency?.name}</div>
              )}
            </div>
          </div>
        </DropdownMenuTrigger>
        <DropdownMenuContent className="min-w-[12rem]">
          <DropdownMenuItem onClick={() => navigate(settingsAccountPath({}))}>
            <AccountIcon width={17} height={17} className="mr-2" />
            Account
          </DropdownMenuItem>
          <DropdownMenuItem
            onClick={() => (window.location.href = serverSignOutPath({}))}
            className="text-redDark focus:text-redDark"
          >
            <LogoutIcon width={17} height={17} className="mr-2" />
            Logout
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>
    </div>
  )
}

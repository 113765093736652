import { zodResolver } from "@hookform/resolvers/zod"
import { useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import toast from "react-hot-toast"
import { z } from "zod"
import { signInPath } from "~/common/paths"
import { useSafeMutation } from "~/common/useSafeMutation"
import { getGraphqlErrors } from "~/common/validations"
import { Button } from "~/shadcn/ui/button"
import { Form, FormField, FormItem } from "~/shadcn/ui/form"
import { Input } from "~/shadcn/ui/input"
import { ModalDialog, ModalDialogHeader } from "~/ui/ModalDialog"
import { DELETE_USER_MUTATION } from "./api"
import { Label } from "~/shadcn/ui/label"

const formSchema = z.object({
  confirmation: z
    .string()
    .optional()
    .refine(
      (value) => value === "DELETE",
      "Please enter DELETE to confirm deletion."
    ),
})

type FormValues = z.infer<typeof formSchema>

export const UserDeleteButton = ({ userId }: { userId: string }) => {
  const [dialogOpen, setDialogOpen] = useState(false)
  const [deleteUser] = useSafeMutation(DELETE_USER_MUTATION)

  const form = useForm<FormValues>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      confirmation: "",
    },
    mode: "onChange",
  })

  const onSubmit = async () => {
    let result = await deleteUser({
      variables: {
        input: {
          id: userId,
        },
      },
    })
    if (result.errors) {
      const errors = getGraphqlErrors(result.errors) || []
      toast.error(errors[0].message)
      return
    }
    window.location.href = signInPath({})
  }

  // Because this is a nested form, we need to do extra work prevent the parent form from submitting
  // We disable default onSubmit behaviour and manually re-enable the enter key here
  const handleKeyDown = (event: React.KeyboardEvent<HTMLFormElement>) => {
    if (event.key === "Enter") {
      event.preventDefault()

      if (form.formState.isValid) {
        event.stopPropagation()
        form.handleSubmit(onSubmit)()
      }
    }
  }

  useEffect(() => {
    if (dialogOpen) {
      form.reset()
    }
  }, [form, dialogOpen])

  return (
    <>
      <Button
        variant="linkSecondary"
        size="none"
        onClick={() => setDialogOpen(true)}
        type="button"
        className="text-redDark"
      >
        Delete my account
      </Button>
      <ModalDialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        size="sm"
      >
        <ModalDialogHeader
          title="Permanently Delete Your Account?"
          subtitle="This action will result in the deletion of your account, your agency from the system, and the influencers you have added."
        />
        <Form {...form}>
          <form
            onSubmit={(event) => {
              event.stopPropagation()
              event.preventDefault()
              form.handleSubmit(onSubmit)()
            }}
            onKeyDown={handleKeyDown}
            className="flex flex-col gap-4"
          >
            <FormField
              control={form.control}
              name="confirmation"
              render={({ field }) => (
                <FormItem>
                  <Label htmlFor="confirmation">
                    Please type DELETE to confirm
                  </Label>
                  <Input {...field} id="confirmation" />
                </FormItem>
              )}
            />
            <div className="flex justify-between">
              <Button
                type="button"
                onClick={() => setDialogOpen(false)}
                variant="linkSecondary"
                size="none"
              >
                Cancel
              </Button>
              <Button
                type="button"
                onClick={form.handleSubmit(onSubmit)}
                disabled={!form.formState.isValid}
              >
                Delete Account
              </Button>
            </div>
          </form>
        </Form>
      </ModalDialog>
    </>
  )
}
